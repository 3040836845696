import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { RatingService } from '../rating.service';

@Component({
    selector: 'app-rating-details',
    templateUrl: './rating-details.component.html',
    styleUrls: ['./rating-details.component.scss']
})
export class RatingDetailsComponent implements OnInit {
    tradeServiceReviews;
    averageRating             = 0;
    totalReview               = 0;
    tradeServiceReviewsId;

    feedbackText;

    jobName;
    userName;
    ratingDate;
    overallRating;
    estimateQuicklyProvided;
    experience;
    price;
    respectForProperty;
    jobQuality;
    userId;
    status

    constructor(
        private router: Router,
        private ratingService: RatingService,
        private route: ActivatedRoute,
        ) {
        this.route.params.subscribe(params => {
            this.tradeServiceReviewsId = (params.id);
        });
    }

    ngOnInit(): void {
        this.getRatingDetails(this.tradeServiceReviewsId);
    }

    approveRating(){
        if (localStorage.getItem('user') !== null) {
            let userId = JSON.parse(localStorage.getItem('user')).id;
            this.ratingService.approveRating(this.tradeServiceReviewsId, userId)
                .subscribe(
                    response => {
                        if(response === 200){
                            this.router.navigate(['/rating-management']);
                        }
                    },
                    error => {
                    console.log(error);
                    }
                );
            }
    }

    rejectRating(){
        if (localStorage.getItem('user') !== null) {
            let userId = JSON.parse(localStorage.getItem('user')).id;

            this.ratingService.rejectRating(this.tradeServiceReviewsId, userId)
                .subscribe(
                    response => {
                        if(response === 200){
                            this.router.navigate(['/rating-management']);
                        }
                    },
                    error => {
                    console.log(error);
                    }
                );
            }
    }

    getRatingDetails(tradeServiceReviewsId): void{
        this.ratingService.getRatingDetails(tradeServiceReviewsId)
          .subscribe(
            response => {
                this.jobName = response.jobName;
                this.userName = response.user.userName;
                this.ratingDate = response.ratingDate;
                this.feedbackText = response.feedbackText;

                this.overallRating = response.overallRating;
                this.estimateQuicklyProvided  = response.estimateQuicklyProvided;
                this.experience  = response.experience;
                this.price  = response.price;
                this.respectForProperty  = response.respectForProperty;
                this.jobQuality  = response.jobQuality;
                this.status = response.status;
            },
            
            error => {
              console.log(error);
            }
          );
      }
}
