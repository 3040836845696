import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserRuleListComponent } from './user-rule-list/user-rule-list.component';
import { UserRuleCreateComponent } from './user-rule-create/user-rule-create.component';
import { UserTaskListComponent } from './user-task-list/user-task-list.component';
import { UserTaskCreateComponent } from './user-task-create/user-task-create.component';
import { UserTaskMapperComponent } from './user-task-mapper/user-task-mapper.component';
import { UserRuleMapperComponent } from './user-rule-mapper/user-rule-mapper.component';
import { UserRuleMapperListComponent } from './user-rule-mapper-list/user-rule-mapper-list.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatSliderModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MailComponent } from 'app/main/mail/mail.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';

const appRoutes: Routes = [
    { path      : 'add-new-user', component: UserCreateComponent },
    { path      : 'update-user/:id', component: UserCreateComponent },
    { path      : 'user-list', component: UserListComponent },

    { path      : 'add-new-user-rule', component: UserRuleCreateComponent },
    { path      : 'update-user-rule/:id', component: UserRuleCreateComponent },
    { path      : 'user-rule-list', component: UserRuleListComponent },

    { path      : 'add-new-user-task', component: UserTaskCreateComponent },
    { path      : 'update-user-task/:id', component: UserTaskCreateComponent },
    { path      : 'user-task-list', component: UserTaskListComponent },

    { path      : 'map-rule-task', component: UserTaskMapperComponent },
  
    // { path      : 'user-rule-mapper/:id', component: UserRuleMapperComponent },
    { path      : 'user-rule-mapper', component: UserRuleMapperComponent },
    { path      : 'user-rule-mapper-list', component: UserRuleMapperListComponent },
    
    { path      : 'reset-password', component: ResetPasswordComponent },
    // { path      : 'email', component: MailComponent },
];

@NgModule({
    declarations: [
        UserCreateComponent,
        UserListComponent,
        UserRuleListComponent,
        UserRuleCreateComponent,
        UserTaskListComponent,
        UserTaskCreateComponent,
        UserTaskMapperComponent,
        UserRuleMapperComponent,
        UserRuleMapperListComponent,
        ResetPasswordComponent,
        PasswordStrengthComponent],
    imports: [
        MatProgressBarModule,
        RouterModule.forChild(appRoutes),
        BrowserModule,
        A11yModule,
        FormsModule,
        ReactiveFormsModule,
        CdkAccordionModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        FlexLayoutModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserModule {}