import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UserModel } from 'app/main/model/userModel';
import { UserService } from '../user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  id: number;
  form: FormGroup;
  userModel: UserModel;
  buttonText: string;
  isLoading: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    private location: Location,
    private userService: UserService,) {
      this.userModel = new UserModel();
     }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.form                      = this.createDrawHomeForm();

    this.id = this.route.snapshot.params['id'];
    if (this.id === undefined){
      this.buttonText        = 'Save';
      this.userModel = new UserModel();
    }else{
      this.buttonText        = 'Update';
      this.isLoading = true;   
      this.userService.getUserById(this.id)
      .subscribe(data => {
        this.isLoading = false;
        this.form.patchValue(data);
      }, error => console.log(error));
    }
  }

  createDrawHomeForm(): FormGroup{
    return this.fb.group({
      userId              : [this.userModel.userId],
      userName            : [this.userModel.userName],
      email               : [this.userModel.email],
      password            : [this.userModel.password],
      isActive            : [this.userModel.isActive]
    });
  }

  goBack(){
    this.location.back();
  }

  submit(): void{
    if(this.buttonText === 'Save'){
    const user = {userName: this.form.value.userName, email: this.form.value.email, password: this.form.value.password};
    this.isLoading = true;
    this.userService.registerUser(user).subscribe(
        (response) => {
          if(response === 200){
            this.isLoading = false;
            this.router.navigate(['/user-list']);
          }
        },
        (error) => { //Error callback
          alert(error);
        }
      );
    }
    else{
      const user = {userName: this.form.value.userName, email: this.form.value.email, password: this.form.value.password};
      this.userService.update(this.form.value).subscribe(
        (response) => {
          if(response === 200){
            this.isLoading = false;
            this.router.navigate(['/user-list']);
          }
        },
        (error) => {
          alert(error);
          console.error(error);
        }
      );
    }
  }

}