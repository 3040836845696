import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit {
  isLoading: boolean = false;
  notification: any;
  notificationId

  companyName
  address
  identityName
  insuredAmount
  expiryDate
  notificationText

  constructor(
      //private router: Router,
      private toastr            : ToastrService,
      private vettingService: VettingService,
      private router            : ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.router.params.subscribe(
      (params: Params) =>{
        this.notificationId = params['notificationId'];
      }
    )
    
    this.getClaimedService();
}

getClaimedService(){
  let userId = JSON.parse(localStorage.getItem('user')).id;
  this.isLoading = true;
  this.vettingService.getNotification(userId).subscribe(responce => {
    this.isLoading = false;
    this.notification = responce.filter(s => s.notificationId  == this.notificationId)[0];

    this.companyName = this.notification['companyName'];
    this.address = this.notification['address'];
    this.identityName = this.notification['identityName'];
    this.insuredAmount = this.notification['insuredAmount'];
    this.expiryDate = this.notification['expiryDate'];
    this.notificationText = this.notification['notificationText'];
  });
}

}
