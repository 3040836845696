import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { AccountService } from 'app/main/pages/authentication/login/account.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { JsonpClientBackend } from '@angular/common/http';
import { ProfileService } from 'app/main/tradesperson/profile/profile.service';
import { Subject } from 'rxjs';

// import { GlobalConstants } from 'app/common/global-constants';

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy
{
    profilePicture
    fuseConfig: any;
    navigation: any;
    emailId: string;
    userRule: string;
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
    constructor(
        private profileService: ProfileService,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private _accountService: AccountService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.profilePicture = 'assets/images/avatars/default-user.jpg';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, {static: true})
    set directive(theDirective: FusePerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._fusePerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._fusePerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }
    userId

    goToHome(){
        this._router.navigate(['/home']);
    }
    ngOnInit(): void
    {

        if(localStorage.getItem('user') != null){
            this.emailId = JSON.parse(localStorage.getItem('user')).email;
            this.userRule = JSON.parse(localStorage.getItem('user')).userRuleName;
            // this.userId = JSON.parse(localStorage.getItem('user')).userId;
            this.userId = JSON.parse(localStorage.getItem('user')).id;
        }

        this.loadProfile();
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._fuseSidebarService.getSidebar('navbar') )
                    {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });
    }

    
    loadProfile(): void{
        // if(JSON.parse(localStorage.getItem("user")).picture === ""){
        //     this.profilePicture = 'assets/images/avatars/default-user.jpg';
        // }
        // else{
        //     this.profilePicture = JSON.parse(localStorage.getItem("user")).picture;
        // }

        // this.profileService.getMyProfileById(this.userId)
        //   .subscribe(data => {
        //       if(data !== null){
        //         if(data.profilePicture === null){
        //             this.profilePicture = 'assets/images/avatars/default-user.jpg';
        //           }
        //           else{
        //             this.profilePicture = data.profilePicture;
        //           }
        //       }else{
        //         this.profilePicture = 'assets/images/avatars/default-user.jpg';
        //       }
        //   }, error => console.log(error));
      }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}