export const ProvideServicesConfig = [
    { name: 'Test', formControlName: 'test', iconUrl: '' },
    { name: '24 Hour Call-Out', formControlName: 'callOut', iconUrl: '' },
    { name: 'All Work Guaranteed', formControlName: 'allWorkGuaranteed', iconUrl: '' },
    { name: 'Commercial Work Covered', formControlName: 'commercialWorkCovered', iconUrl: '' },
    { name: 'Domestic Work Covered', formControlName: 'domesticWorkCovered', iconUrl: '' },
    { name: 'Family Business', formControlName: 'familyBusiness', iconUrl: '' },
    { name: 'Free Quotes / Estimates', formControlName: 'freeQuotes', iconUrl: '' },
    { name: 'Insurance Work Undertaken', formControlName: 'insuranceWork', iconUrl: '' },
    { name: 'Long Established Company', formControlName: 'longEstablishedCompany', iconUrl: '' },
    { name: 'NHS Discounts', formControlName: 'nhsDiscounts', iconUrl: '' },
    { name: 'OAP Discounts', formControlName: 'oapDiscount', iconUrl: '' },
    { name: 'Over 10 Years Experience', formControlName: 'overExperience', iconUrl: '' },
    { name: 'Over 10 Years Trading', formControlName: 'overTrading', iconUrl: '' },
    { name: 'Cards Accepted', formControlName: 'cardsAccepted', iconUrl: '' },
    { name: 'Women-led Business', formControlName: 'womenledBuisness', iconUrl: '' },
]