import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';
import { ImageViewerModule } from 'ng2-image-viewer';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';

import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { VettingCreateComponent } from './vetting-create/vetting-create.component';
import { VettingListComponent } from './vetting-list/vetting-list.component';
import { VettingListForsupervisorComponent } from './vetting-list-forsupervisor/vetting-list-forsupervisor.component';
import { VettingManagementComponent } from './vetting-management/vetting-management.component';
import { VettingRejectComponent } from './vetting-reject/vetting-reject.component';
import { VettingScrapedTradespersonComponent } from './vetting-scraped-tradesperson/vetting-scraped-tradesperson.component';
import { VettingManagementClaimedComponent } from './vetting-management-claimed/vetting-management-claimed.component';
import { MatInputModule } from '@angular/material/input';
import { VettingManagementNewTradespersonComponent } from './vetting-management-new-tradesperson/vetting-management-new-tradesperson.component';
import { NotificationComponent } from './notification/notification.component';
import { ServiceReviewComponent } from './service-review/service-review.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { AddNotificationComponent } from './add-notification/add-notification.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { AnnouncementCreateComponent } from './announcement-create/announcement-create.component';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import { CommunicationComponent } from './communication/communication.component';
import { VettingNewTradespersonComponent } from './vetting-new-tradesperson/vetting-new-tradesperson.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FlexLayoutModule } from '@angular/flex-layout';

const appRoutes: Routes = [
    { path      : 'vetting-management', component: VettingManagementComponent },
    { path      : 'update-vetting/:id', component: VettingManagementComponent },
    { path      : 'vetting-reject/:id/:userId', component: VettingRejectComponent },
    { path      : 'vetting-list', component: VettingListComponent },
    { path      : 'check-vetting/approved', component: VettingListForsupervisorComponent },
    { path      : 'check-vetting/unapproved', component: VettingListForsupervisorComponent },
    { path      : 'dialog', component: DocumentViewerComponent },
    { path      : 'claimed-tradesperson', component: VettingScrapedTradespersonComponent },
    { path      : 'claimed-tradesperson-vetting/:id/:userid', component: VettingManagementClaimedComponent },
    { path      : 'new-tradesperson-vetting/:id/:userid', component: VettingManagementNewTradespersonComponent },
    { path      : 'notification', component: NotificationComponent },
    { path      : 'service-review', component: ServiceReviewComponent },
    { path      : 'announcement', component: AnnouncementComponent },
    { path      : 'notification-details/:notificationId', component: NotificationDetailComponent },
    { path      : 'add-notification', component: NotificationListComponent },
    { path      : 'notification-entry/:tradeServiceId/:insuredAmount/:expiryDate/:companyName', component: AddNotificationComponent },
    { path      : 'create-announcement', component: AnnouncementCreateComponent },
    { path      : 'my-bookmarks', component: BookmarksComponent },
    { path      : 'communication', component: CommunicationComponent },
    { path      : 'new-tradesperson', component: VettingNewTradespersonComponent },
];


@NgModule({
    declarations: [
    DocumentViewerComponent,
    VettingCreateComponent,
    VettingListComponent,
    VettingListForsupervisorComponent,
    VettingManagementComponent,
    VettingRejectComponent,
    VettingScrapedTradespersonComponent,
    VettingManagementClaimedComponent,
    VettingManagementNewTradespersonComponent,
    NotificationComponent,
    ServiceReviewComponent,
    AnnouncementComponent,
    NotificationDetailComponent,
    AddNotificationComponent,
    NotificationListComponent,
    AnnouncementCreateComponent,
    BookmarksComponent,
    CommunicationComponent,
    VettingNewTradespersonComponent,
],
    imports: [
        RouterModule.forChild(appRoutes),
        BrowserModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        FuseSharedModule,
        ImageViewerModule,
        AngularEditorModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatTabsModule,
        MatTableModule,
        MatInputModule,
        MatProgressBarModule,
        FlexLayoutModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class VettingModule {}
