import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { DocumentViewerComponent } from '../document-viewer/document-viewer.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TradeService } from 'app/main/model/tradeService';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-vetting-management',
  templateUrl: './vetting-management.component.html',
  styleUrls: ['./vetting-management.component.scss']
})
export class VettingManagementComponent implements OnInit {
    tradeServiceCostList = [{
        itemCategoryId: 0,
        itemCategory: {
            itemCategoryName: ''
        },
        serviceCost: '',
        tradeServiceCostId: 0,
        tradeServiceId: 0
    }]
    vettingList = [{
        vettingHead: '',
        vettingValueofHead: '',
    }];

    ourServices = [{
                    allWorkGuaranteed: false,
                    callOut: false,
                    cardsAccepted: false,
                    commercialWorkCovered: false,
                    domesticWorkCovered: false,
                    familyBusiness: false,
                    freeQuotes: false,
                    insuranceWork: false,
                    longEstablishedCompany: false,
                    nhsDiscounts: false,
                    oapDiscount: false,
                    ourServicesId: 0,
                    overExperience: false,
                    overTrading: false,
                    tradeService: null,
                    tradeServiceId: 0,
                    womenledBuisness: false
        }]


    portfolioImage1                     : string;
    portfolioImage2                     : string;
    portfolioImage3                     : string;
    portfolioImage4                     : string;
    portfolioImage5                     : string;
    portfolioImage6                     : string;
    portfolioImage7                     : string;
    portfolioImage8                     : string;
    portfolioImage9                     : string;
    portfolioImage10                    : string;
    
    identityFile                        : string;
    companyRegisterCertificateFile      : string;
    vATCertificateFile                  : string;
    insuranceCertificateFile            : string;
    primaryCategory  
    tradeService;   
    id;

    isLoading: boolean = false;

  constructor(private vettingService: VettingService,
    private _matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private toastr            : ToastrService) {
    this.tradeService = new TradeService();
   }


  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getVettingManagementData(this.id)
  }
  
  approved(){
    this.vettingService.approvedTradeService(this.id)
        .subscribe((response) => {
            this.router.navigate(['vetting-list']);
        this.toastr.success('Tradesperson approved successfully!!', 'BRP');

     },
    );
  }
  
  displayIdentityFile(): void
  {
     let  vattingId = this.identityFile;
      this._matDialog.open(DocumentViewerComponent, {
          panelClass: 'knowledgebase-article-dialog',
          data      : {vattingId}
      });
  }

  displayCompanyRegisterCertificateFile(): void
  {
     let  vattingId = this.companyRegisterCertificateFile;
      this._matDialog.open(DocumentViewerComponent, {
          panelClass: 'knowledgebase-article-dialog',
          data      : {vattingId}
      });
  }

  displayVatCertificateFile(): void
  {
     let  vattingId = this.vATCertificateFile;
      this._matDialog.open(DocumentViewerComponent, {
          panelClass: 'knowledgebase-article-dialog',
          data      : {vattingId}
      });
  }

  getVettingManagementData(tradeServiceId){
    this.isLoading = true;
    this.vettingService.getVettingManagementData(tradeServiceId)
        .subscribe((response) => {
            this.tradeService = response;
            this.ourServices = this.tradeService.ourServices;
            this.primaryCategory = this.tradeService.itemCategory.itemCategoryName;


            this.tradeServiceCostList = this.tradeService.tradeServiceCostList;
            this.vettingList = this.tradeService.vettingList;
            
            this.vettingList.forEach(resource => {
                if(resource.vettingHead ==='IdentityFile')
                {
                    this.identityFile = resource.vettingValueofHead;
                }
                else if(resource.vettingHead ==='CompanyRegisterCertificateFile')
                {
                    this.companyRegisterCertificateFile = resource.vettingValueofHead;
                }
                else if(resource.vettingHead ==='VATCertificateFile')
                {
                    this.vATCertificateFile = resource.vettingValueofHead;
                };
            });
            
            if(this.tradeService.portfolioList[0].resourceFile != 'undefined'){
                this.portfolioImage1 = this.tradeService.portfolioList[0].resourceFile;
            }
            if(this.tradeService.portfolioList[1].resourceFile != 'undefined'){
                this.portfolioImage2 = this.tradeService.portfolioList[1].resourceFile;
            }
            if(this.tradeService.portfolioList[2].resourceFile != 'undefined'){
                this.portfolioImage3 = this.tradeService.portfolioList[2].resourceFile;
            }



            if(this.tradeService.portfolioList[3].resourceFile != 'undefined'){
                this.portfolioImage4 = this.tradeService.portfolioList[3].resourceFile;
            }
            
            if(this.tradeService.portfolioList[4] === 'undefined'){
                this.portfolioImage5 = this.tradeService.portfolioList[4].resourceFile;
            }
            if(this.tradeService.portfolioList[5] === 'undefined'){
                this.portfolioImage6 = this.tradeService.portfolioList[5].resourceFile;
            }
            if(this.tradeService.portfolioList[6] === 'undefined'){
                this.portfolioImage7 = this.tradeService.portfolioList[6].resourceFile;
            }
            if(this.tradeService.portfolioList[7] === 'undefined'){
                this.portfolioImage8 = this.tradeService.portfolioList[7].resourceFile;
            }
            if(this.tradeService.portfolioList[8] === 'undefined'){
                this.portfolioImage9 = this.tradeService.portfolioList[8].resourceFile;
            }
            if(this.tradeService.portfolioList[9] === 'undefined'){
                this.portfolioImage10 = this.tradeService.portfolioList[9].resourceFile;
            }
            this.isLoading = false;
        },
    );
  }

  moveToSelectedTab(tabName: string): void {
        for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
            if ((document.querySelectorAll('.mat-tab-label-content')[i] as HTMLElement).innerText === tabName)
            {
                (document.querySelectorAll('.mat-tab-label')[i] as HTMLElement).click();
            }
        }
  }
}
