import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';

// import { AuthService } from 'app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class ResetPasswordService
{
    private messageSource     = new BehaviorSubject('default message');
    currentMessage            = this.messageSource.asObservable();

    private sourceModel       = new BehaviorSubject(null);
    currentModel              = this.sourceModel.asObservable();

    private siblingMsg = new Subject<string>();
    constructor(private _httpClient   : HttpClient,
                // private authService   : AuthService
      ) {}

      public getMessage(): Observable<string> {
        return this.siblingMsg.asObservable();
      }

      public updateMessage(message: string): void {
        this.siblingMsg.next(message);
      }
      
    registerUser(user): Observable<any> {
        const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'}
        return this._httpClient
        .post(environment.baseUrl +'/User/Register', user)
        .pipe(
          catchError(this.errorHandler)
        );
      }

    login(userLogin) {
      const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'};

      return this._httpClient
      .post<any>(environment.baseUrl + '/User/AuthenticateForPlatformUser', userLogin)
      .pipe(
         catchError(this.errorHandler)
      );
    }

    changePassword(userId) {
      const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'};

      return this._httpClient
      .get<any>(environment.baseUrl + '/User/ForgotPassword/'+ userId)
      .pipe(
         catchError(this.errorHandler)
      );
    }

    change(user) {
      const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'};

      return this._httpClient
      .post<any>(environment.baseUrl + '/User/ChangePassword', user)
      .pipe(
         catchError(this.errorHandler)
      );
    }

    sendEmailForForgotPassword(email) {
      const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'};

      return this._httpClient
      .get<any>(environment.baseUrl + '/User/SendEmailForForgotPassword/'
      + email+ '/platform')
      .pipe(
         catchError(this.errorHandler)
      );
    }

    errorHandler(error) {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          errorMessage = error.error.message;
        } else {
          // errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
          errorMessage = `${error.error}`;
        }
        return throwError(error);
     }
}
