import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  

  getMaterials(): Observable<any> {
    return this.http.get(environment.baseUrl + '/Administrative/GetMaterials')
    .pipe(
      catchError(this.errorHandler)
    );
  }

  saveMaterial(material: Object): Observable<Object> {
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'} 
    return this.http.post(environment.baseUrl + '/Administrative/SaveMaterial', material, {'headers':headers})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  updateMaterial(material: Object): Observable<Object> {
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'} 
    return this.http.post(environment.baseUrl + '/Administrative/UpdateMaterial', material, {'headers':headers})
    .pipe(
      catchError(this.errorHandler)
    );
  }

  
  getMaterialById(materialId): Observable<any> {
    return this.http.get(environment.baseUrl + '/DrawHome/GetMaterialById/' + materialId);
  }

  // getMaterialBySubcategoryId(subcategoryId: number): Observable<any> {
  //   return this.http.get(`${this.baseUrl}` + 'Itemcategory/GetSubCategory/' + subcategoryId);
  // }

  getAllItemCategory(): Observable<any> {
    return this.http.get(environment.baseUrl + '/Itemcategory/GetAllCategory');
  }
  update(id, post): Observable<any> {
    return this.http.put<any>(environment.baseUrl + '/posts/' + id, JSON.stringify(post), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    );
  }
  

  deleteMaterial(id: number): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/${id}`, { responseType: 'text' });
  }

  getMaterialList(): Observable<any> {
    return this.http.get(environment.baseUrl + '/Itemcategory/GetSubCategory');
  }

  // tslint:disable-next-line: typedef
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `${error.error.message}`;
    }
    return throwError(errorMessage);
 }
 
}
