import { Component, OnInit } from '@angular/core';

import { RatingService } from 'app/main/administrative/rating/rating.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  ratingList: string;
  isLoading: boolean = false;

  //'email',
  ratingListColumns: string[] = ['position', 'name',  'email', 'postcode', 'address', 'contactToTradespersonId'];

  constructor(
      private router: Router,
      private ratingService: RatingService,
  ) {}

  ngOnInit(): void {
      this.reloadData();
  }

  userId
  reloadData(): void {
    if (JSON.parse(localStorage.getItem('user')).id != null){
      this.userId = JSON.parse(localStorage.getItem('user')).id;
    }
    this.isLoading = true;
      this.ratingService.getContactToTradesperson(this.userId).subscribe(x => {
          this.ratingList = x;
          this.isLoading = false;
      });
  }

  checkDetail(id: number): void {
      this.router.navigate(['job-details', id]).then();
  }

}

