import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { MaterialModel } from 'app/main/model/materialModel';
import { MaterialService } from '../material.service';

@Component({
  selector: 'app-material-create',
  templateUrl: './material-create.component.html',
  styleUrls: ['./material-create.component.scss']
})
export class MaterialCreateComponent implements OnInit {

  id: number;
  form: FormGroup;
  materialModel: MaterialModel;
  buttonText: string;


  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    private router: Router,
    private materialService: MaterialService,) {
      this.materialModel = new MaterialModel();
     }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.form                      = this.createDrawHomeForm();

    this.id = this.route.snapshot.params['id'];
    if (this.id === undefined){
      this.buttonText        = 'Save';
      this.materialModel = new MaterialModel();
    }else{
      this.buttonText        = 'Update';   
      this.materialService.getMaterialById(this.id)
      .subscribe(data => {
        this.form.setValue(data[0]);
      }, error => console.log(error));
    }
  }

  createDrawHomeForm(): FormGroup{
    return this.fb.group({
      materialId              : [this.materialModel.materialId],
      materialName            : [this.materialModel.materialName],
    });
  }

  submit(): void{
    if(this.buttonText == 'Save'){
      this.materialService.saveMaterial(this.form.value).subscribe(
        (response) => {
          if(response === 200){
            this.router.navigate(['/material-list']);
          }
        },
        (error) => {
          alert(error);
          console.error(error);
        }
      );
    }else{
      this.materialService.updateMaterial(this.form.value).subscribe(
        (response) => {
          if(response === 200){
            this.router.navigate(['/material-list']);
          }
        },
        (error) => {
          alert(error);
          console.error(error);
        }
      );
    }
  }

}
