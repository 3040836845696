import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";
// import { AuthService } from "app/auth/auth.service";

@Injectable({
    providedIn: "root",
})
export class TradespersonScraperService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http          : HttpClient,
              // private authService   : AuthService
              ) { 
                
              }

  getRating(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetTradeServiceReviewsForVerification');
  }

  getRatingDetails(tradeServiceReviewsId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRatingDetails/' + tradeServiceReviewsId);
  }

  approveRating(tradeServiceReviewsId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/ApproveTradeServiceReview/'+ tradeServiceReviewsId + '/' + userId);
  }

  rejectRating(tradeServiceReviewsId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/RejectTradeServiceReview/'+ tradeServiceReviewsId + '/' + userId);
  }

  getScrapedTradesperson(categoryId): Observable<any> {

    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetScrapedTradesperson/' + categoryId);
  }

  deleteScrapedDataCategorywise(itemCategoryId): Observable<any> {

    return this.http
    .delete(environment.baseUrl + '/api/Tradesman/DeleteScrapedDataCategorywise/' + itemCategoryId);
  }

  importScraptdTradesperson(itemCategoryId): Observable<any> {
    return this.http
    .get('http://localhost:8009/api/TradespersonLoader/GetScrapedTradesperson/' + itemCategoryId);
  }

  deleteScrapedDataTradespersonwise(tradespersonId): Observable<any> {
    return this.http
    .delete(environment.baseUrl + '/api/Tradesman/DeleteScrapedDataTradespersonwise/' + tradespersonId);
  }
  getItemCategory(): Observable<any> {
   
    return this.http
    .get(environment.baseUrl + '/Itemcategory/GetAllCategory');

  }


  getContactToTradesperson(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetContactToTradesperson/' + tradeServiceId);
  }

  
  getRatingDetailsTest(tradeServiceReviewsId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRatingDetailsTest/' + tradeServiceReviewsId);
  }
  
  // tslint:disable-next-line: typedef  GetTradeService
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
 
}
