import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  ratingListColumns: string[] = ['position', 'companyName', 'companyOwner', 'tradespersonCell', 'estimatedBudget', 'jobDescription'];
  isLoading: boolean = false;
  constructor(
      private router: Router,
      private toastr            : ToastrService,
      private vettingService: VettingService,
  ) {}
  notificationList: any;
  communicationToTradesperson: any;
  ngOnInit(): void {
      
      this.getNotification();
  }
  
  
  
  getNotification(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.isLoading = true;
    this.vettingService.getCommunicationToTradesperson(userId).subscribe(responce => {
      this.isLoading = false;
      this.communicationToTradesperson = responce;
    });
  }

  checkDetail(notificationId){
    this.router.navigate(['/notification-details', notificationId]);
  }
}

