

export class UserRuleTaskMapper {
    userRuleTaskMapperId: number; 
    userRuleId: number;
     menuId: number;

     constructor(userRuleTaskMapper?) {
        userRuleTaskMapper = userRuleTaskMapper || {};
          this.userRuleTaskMapperId = userRuleTaskMapper.userRuleTaskMapperId || 0;
          this.userRuleId = userRuleTaskMapper.userRuleId || 0;
          this.menuId = userRuleTaskMapper.menuId || 0;
          
        }
}