import { Component, OnInit } from '@angular/core';

import { ItemCategoryService } from '../item-category.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-category-list',
  templateUrl: './item-category-list.component.html',
  styleUrls: ['./item-category-list.component.scss']
})
export class ItemCategoryListComponent implements OnInit {
  categoryList: any;

  constructor(
    private itemCategoryService: ItemCategoryService,
    private router: Router) { }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    // this.employees = this.employeeService.getEmployeesList();
    this.itemCategoryService.getCategoryList().subscribe(x => {
      this.categoryList =  x;
    });
  }

  deleteCategory(id: number): void {
    
  }

  categoryDetail(id: number): void{
    this.router.navigate(['update-item-category', id]);
  }

}
