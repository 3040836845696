import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { TradespersonScraperService } from '../tradesperson-scraper.service';

import { ToastrService } from 'ngx-toastr';

interface Food {
  value: string;
  viewValue: string;
}

interface Car {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-tradesperson-scraper-list',
  templateUrl: './tradesperson-scraper-list.component.html',
  styleUrls: ['./tradesperson-scraper-list.component.scss']
})
export class TradespersonScraperListComponent implements OnInit {
  ratingList: string;
  
  ratingListColumns: string[] = ['position', 'cellNo',  'companyName', 'addressLine1', 'tradeServiceReviewsId'];

  constructor(
      private router: Router,
      private toastr            : ToastrService,
      private tradespersonScraperService: TradespersonScraperService,
  ) {}
  categorys: any;
  selectedValue: string;
  ngOnInit(): void {
      this.loadItemCategory();
  }
  
  loadItemCategory(){
    this.tradespersonScraperService.getItemCategory().subscribe(responce => {
        this.categorys = responce;
    });
  }

  loadScrapedTradesperson(): void {
      this.tradespersonScraperService.getScrapedTradesperson(this.selectedValue).subscribe(x => {
          this.ratingList = x;

      });
      this.toastr.success('Please select category!!', 'BRP');
  }

  import(){
    // http://localhost:8009/api/TradespersonLoader?collectionName=electrician-demo

    this.tradespersonScraperService.importScraptdTradesperson('electrician-demo').subscribe(x => {
      this.ratingList = x;
    });
    this.toastr.success('Already imported', 'BRP');
  }

  deleteScrapedDataCategorywise(){
    this.toastr.success('Are you sure you want to delete category?', 'BRP');

    this.toastr.success('Please select category!!', 'BRP');
      this.tradespersonScraperService.deleteScrapedDataCategorywise(this.selectedValue).subscribe(x => {
          this.ratingList = x;
      });
  }

  deleteScrapedDataTradespersonwise(tradespersonId){

    this.toastr.success('Are you sure you want to delete category?', 'BRP');
      this.tradespersonScraperService.deleteScrapedDataTradespersonwise(tradespersonId).subscribe(x => {
          this.ratingList = x;
      });
  }

  checkDetail(id: number): void {
      this.router.navigate(['rating-details', id]).then();
  }

}

