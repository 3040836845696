import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ForgotPasswordService } from './forgot-password.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{
    forgotPasswordForm: FormGroup;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private forgotPasswordService: ForgotPasswordService,
        private toastr            : ToastrService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            email   : ['', [Validators.required]],
            
        });
    }

    get f(): any { return this.forgotPasswordForm.controls; }

    sendResetLink(): void {
        this.forgotPasswordService.sendEmailForForgotPassword(this.forgotPasswordForm.value.email)
        //   .pipe(first())
          .subscribe(
            data => {
                this.toastr.info('Please check your email');
            },
            error => {
            //   this.toast.error(error, 'Something is Wrong!');
            });
    }
}
