import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { UserRuleModel } from 'app/main/model/userRule';
import { UserService } from '../user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-rule-create',
  templateUrl: './user-rule-create.component.html',
  styleUrls: ['./user-rule-create.component.scss']
})
export class UserRuleCreateComponent implements OnInit {

  id: number;
  form: FormGroup;
  userRuleModel: UserRuleModel;
  buttonText: string;
  isLoading: boolean = false;


  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    private location: Location,
    private userService: UserService,) {
      this.userRuleModel = new UserRuleModel();
     }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.form                      = this.createDrawHomeForm();

    this.id = this.route.snapshot.params['id'];
    if (this.id === undefined){
      this.buttonText        = 'Save';
      this.userRuleModel = new UserRuleModel();
    }else{
      this.buttonText        = 'Update';   
      this.isLoading= true;
      this.userService.getUserRuleId(this.id)
      .subscribe(data => {
        this.isLoading = false;
        this.form.patchValue(data);
      }, error => console.log(error));
    }
  }
  goBack(){
    this.location.back();
  }
  createDrawHomeForm(): FormGroup{
    return this.fb.group({
      userRuleId              : [this.userRuleModel.userRuleId],
      userRuleName            : [this.userRuleModel.userRuleName],
    });
  }

  submit(): void{}

}