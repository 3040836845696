export class MaterialModel {
  materialId: number;
  materialName: string;
    

    constructor(material?) {
        material = material || {};
        this.materialId = material.materialId || 0;
        this.materialName = material.materialName;

      }
}
