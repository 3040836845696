import { Component, OnInit } from '@angular/core';
import { VettingService } from '../vetting.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vetting-new-tradesperson',
  templateUrl: './vetting-new-tradesperson.component.html',
  styleUrls: ['./vetting-new-tradesperson.component.scss']
})
export class VettingNewTradespersonComponent implements OnInit {
  waitingList: string;
  approvedList: string;
  newApprovedList: string;
  isLoading: boolean = false;

  waitingListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo', 'claimed', 'Detail'];
  approvedListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo', 'claimed'];

  newTradeServiceaitingListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo', 'Detail'];
  newTradeServiceApprovedListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo'];

  tradeServiceList;
  newTradeServiceList;
  constructor(
      private vettingService: VettingService,
      private router: Router
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.loadNewTradeServiceData();
  }

  loadData(): void {
    this.isLoading = true;
    this.vettingService.getClaimedService().subscribe(response => {
      this.isLoading = false;
      this.tradeServiceList = response.filter(s => s.isApproved === false);
      this.approvedList = response.filter(a =>  a.isApproved === true);
    });
  }
  
  loadNewTradeServiceData(): void {
    this.isLoading = true;
    this.vettingService.getNewTradeServiceForVetting().subscribe(response => {
      
      this.isLoading = false;
      this.newTradeServiceList = response.filter(s => s.isApproved === false);
      this.newApprovedList = response.filter(a =>  a.isApproved === true);
    });
  }

  checkDetail(id: number, userId: number): void {
    this.router.navigate(['claimed-tradesperson-vetting', id, userId]).then();
  }

  checkDetailNewTradeService(id: number, userId: number): void {
    this.router.navigate(['new-tradesperson-vetting', id, userId]).then();
  }
}

