import { ContentObserver } from '@angular/cdk/observers';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TradeService } from 'app/main/model/tradeService';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../services.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  form: FormGroup;
  tradeService
  serviceWeCoverList;
  checkedServices;
  ourServicesList
  myModel:boolean;
  userId;
  checked: boolean;
  disabled = false;
  tradeServiceId;

  constructor(private servicesService: ServicesService,
              private formBuilder: FormBuilder,
              private toastr            : ToastrService) { 
    //this.tradeService = new TradeService();
    this.form     = this.createGeneralForm();
    this.checked  = false;
  }

  createGeneralForm(): FormGroup {
    return this.formBuilder.group({
      operatingRadius		        : [null],
      areasCovered			        : [null],
      userId                    : 0,
      callOut			              : [this.checked],
      allWorkGuaranteed			    : [this.checked],
      commercialWorkCovered			: [this.checked],
      domesticWorkCovered			  : [this.checked],
      familyBusiness			      : [this.checked],
      freeQuotes			          : [this.checked],
      insuranceWork			        : [this.checked],
      longEstablishedCompany		: [this.checked],
      nhsDiscounts			        : [this.checked],
      oapDiscount			          : [this.checked],
      overExperience			      : [this.checked],
      overTrading			          : [this.checked],
      cardsAccepted			        : [this.checked],
      womenledBuisness			    : [this.checked],
    });
  }
  
  ngOnInit() {
    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    this.getTradeService(this.userId);
    
  }
  
  getTradeService(userId){
      this.servicesService.ourServices(userId)
          .subscribe((response) => {
            this.serviceWeCoverList = response[0];

            if(response[0].isChecked === true){
              this.form.disable();
              this.disabled = true;
            }
            this.form.patchValue({
              operatingRadius           : this.serviceWeCoverList.operatingRadius, 
              areasCovered              : this.serviceWeCoverList.areasCovered,
    
              callOut						        : this.serviceWeCoverList.ourServices.callOut,
              allWorkGuaranteed				  : this.serviceWeCoverList.ourServices.allWorkGuaranteed,
              commercialWorkCovered			: this.serviceWeCoverList.ourServices.commercialWorkCovered,
              domesticWorkCovered			  : this.serviceWeCoverList.ourServices.domesticWorkCovered,
              familyBusiness				    : this.serviceWeCoverList.ourServices.familyBusiness,
              freeQuotes					      : this.serviceWeCoverList.ourServices.freeQuotes,
              insuranceWork					    : this.serviceWeCoverList.ourServices.insuranceWork,
              longEstablishedCompany		: this.serviceWeCoverList.ourServices.longEstablishedCompany,
              nhsDiscounts					    : this.serviceWeCoverList.ourServices.nhsDiscounts,
              oapDiscount					      : this.serviceWeCoverList.ourServices.oapDiscount,
              overExperience				    : this.serviceWeCoverList.ourServices.overExperience,
              overTrading					      : this.serviceWeCoverList.ourServices.overTrading,
              cardsAccepted					    : this.serviceWeCoverList.ourServices.cardsAccepted,
              womenledBuisness				  : this.serviceWeCoverList.ourServices.womenledBuisness,
              })
            },
            (error) => {
              alert(error);
            }
          );


  }

  saveNextTab(tabName: string) {
    
    if(this.form.value.operatingRadius === null || this.form.value.areasCovered === ''){
      this.toastr.success('Please select your operating radius', 'BRP');
      return
    }
    if(this.form.value.areasCovered === null || this.form.value.areasCovered === ''){
      this.toastr.success('Please input area cover section', 'BRP');
      return
    }

    if(this.form.value.allWorkGuaranteed === false &&
      (this.form.value.callOut === false) &&
      (this.form.value.cardsAccepted  === false) &&
      (this.form.value.commercialWorkCovered  === false) &&
      (this.form.value.domesticWorkCovered  === false) &&
      (this.form.value.familyBusiness  === false) &&
      (this.form.value.freeQuotes === false) &&
      (this.form.value.insuranceWork  === false) &&
      (this.form.value.longEstablishedCompany  === false) &&
      (this.form.value.nhsDiscounts  === false) &&
      (this.form.value.oapDiscount === false) &&
      (this.form.value.overExperience === false) &&
      (this.form.value.overTrading === false) &&
      (this.form.value.womenledBuisness === false)){
        this.toastr.success('Please select atleast one service', 'BRP');
        return
      }
      
    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }

    this.form.value.userId = this.userId;
    this.servicesService.saveLocation(this.form.value)
      .subscribe((response) => {//Next callback
        if(response === 200){
          this.toastr.success('Information saved successfully', 'BRP');
          // this.serviceWeCover();
          for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
            if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
              {
                  (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
              }
            }
          }
        },
        (error) => { //Error callback
          alert(error);
        }
      );
      
  }

  previousTab(tabName: string) {
    for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
       {
          (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
       }
     }
  }

}
