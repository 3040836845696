import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { TradeService } from 'app/main/model/tradeService';
import { ServicesService } from '../../services.service';

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}


@Component({
  selector: 'app-job-categories',
  templateUrl: './job-categories.component.html',
  styleUrls: ['./job-categories.component.scss']
})
export class JobCategoriesComponent implements OnInit {

  itemCategorys;
  itemSubCategorys
  itemAllCategorys;
  tradeService
  disabled = false;
  form: FormGroup;
  serviceCostData = [
    {
        isJobCategories: false, 
        itemCategory: {
          itemCategoryId: 0,
          itemCategoryName: ''
        }, 
      tradeServiceCostId: 0, 
      tradeServiceId: 0,
      serviceCost: ''
    }
  ];
  serviceCost = [
    {tradeServiceCostId: 0, 
      tradeServiceId: '', 
      itemCategoryId: '', 
      serviceCost: '', 
      isJobCategories: false
    }
  ];
  selectedSubCategoryIds = []
  userId;
  
  constructor(private formBuilder       : FormBuilder, 
              private servicesService   : ServicesService,
              ) {
    this.tradeService = new TradeService();
  }
  
  ngOnInit(){
    this.form = this.formBuilder.group({
      itemCategoryId: [''], 
    });
    this.getTradeService();
    this.getItemCategory();
  }
  
  getTradeService(){
    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
      
    this.servicesService.getTradeServices(this.userId)
        .subscribe((response) => {
           this.tradeService = response;

           this.serviceCostData = [];
           this.servicesService
            .getServicesCost(response.tradeServiceId, 0)
            .subscribe((response) => {
              this.serviceCostData = response;

                for(let o of this.serviceCostData){
                  if(o.isJobCategories === true){
                    this.selectedSubCategoryIds.push(o.itemCategory.itemCategoryId)
                  }
                }
              },
            );

            this.form.patchValue({
              itemCategoryId: this.tradeService.itemCategoryId
            })
      },
    );
  }
  
  getItemCategory(): void {
    this.servicesService.getItemgategorys().subscribe(x => {
      this.itemAllCategorys = x;
      this.itemCategorys =  x.filter(i => i.parentId === 0);
    });
  }
  
  getItemSubCategory(value): void {
    this.selectedSubCategoryIds = [];
    this.serviceCostData = [];
    
    this.itemSubCategorys = this.itemAllCategorys
    .filter(i => i.parentId === value.itemCategoryId);
    
    for(let o of this.itemSubCategorys){
      this.serviceCostData.push(
        {
          isJobCategories: false, 
            itemCategory: {
            itemCategoryId: o.itemCategoryId,
            itemCategoryName: o.itemCategoryName
          }, 
        tradeServiceCostId: 0, 
        tradeServiceId: 0,
        serviceCost: ''
        }
      );
   }
  }
  
  jobCategoryCheck(itemCategoryId){
    const index: number = this.selectedSubCategoryIds.indexOf(itemCategoryId);
    if (index !== -1) {
        this.selectedSubCategoryIds.splice(index, 1);
    }  
    else{
      this.selectedSubCategoryIds.push(itemCategoryId)
    }
  }
  
  saveNextTab(tabName: string) {
    this.serviceCost = [];
    
    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    
    for (var i = 0; i < this.selectedSubCategoryIds.length; i++) {
      var serviceCost = (<HTMLInputElement>document.getElementById(this.selectedSubCategoryIds[i]))
      
      var serviceCostValue
      if (serviceCost != null) {
        serviceCostValue = serviceCost.value;
      }
      else {
        serviceCostValue = null;
      }
      
      this.serviceCost.push(
        {
          tradeServiceCostId: 0,
          tradeServiceId: this.tradeService.tradeServiceId, 
          itemCategoryId: this.selectedSubCategoryIds[i],
          serviceCost: serviceCostValue,
          isJobCategories: false
        }
      );
    }
    
    this.servicesService.savJobCategory(this.form.value.itemCategoryId, this.serviceCost)
      .subscribe((response) => {//Next callback
        if(response === 200){
          for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
          if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
            {
                (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
            }
          }
        }
        },
        (error) => { //Error callback
          alert(error);
        }
      );
  }
  
  previousTab(tabName: string) {
    for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
       {
          (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
       }
     }
  }
}
