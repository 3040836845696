import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { ResetPasswordComponent } from 'app/main/pages/authentication/reset-password/reset-password.component';
import { ResetPasswordService } from './reset-password.service';
import { RouterModule } from '@angular/router';
import { AuthguardGuard } from 'app/auth/authguard.guard';

const routes = [
    {
        path     : 'reset-password/:Id', component: ResetPasswordComponent,canActivate: [AuthguardGuard]
    }
];


@NgModule({
    declarations: [
        ResetPasswordComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule
    ],
    providers: [ResetPasswordService]
})
export class ResetPasswordModule
{
}
