import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";
// import { AuthService } from "app/auth/auth.service";

@Injectable({
    providedIn: "root",
})
export class ClaimService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  constructor(private http          : HttpClient,
              // private authService   : AuthService
              ) { 
                
              }

  getVettingFileByUserId(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetVettingFileByUserId/' + userId + '/' + tradeServiceId);
  }
  
  saveImaGegallery(vetting): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveImaGegallery', vetting);
  }
  loadImaGegallery(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetImageGegallery/' + userId + '/' + tradeServiceId);
  }

  goVetting(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GoVettingForClaimed/' + userId + '/' + tradeServiceId);
  }

  goVettingForNewTradeService(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GoVettingForNewTradeService/' + userId + '/' + tradeServiceId);
  }

  saveVetting(vetting): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveVettingStatus', vetting);
  }

  saveVettingStatusForNewTradeService(vetting): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveVettingStatusForNewTradeService', vetting);
  }
  
  saveOurService(val): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveOurService', val);
  }

  getOurServices(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetOurServices/'+ userId+ '/' + tradeServiceId);
  }

  getOurServicesForNewTradeService(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetOurServicesForNewTradeService/'+ userId+ '/' + tradeServiceId);
  }

  createClaimedData(generalData, userId): Observable<any> {
    
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/CreateNewTradeService/' + userId, generalData);
  }

  // saveLocation(val): Observable<any> {
  //   return this.http
  //   .post(environment.baseUrl +'/Administrative/SaveLocation', val,
  //   { headers: this.authService.getHeaders() });
  // }

  getTradeServices(userId): Observable<any> {
    return this
    .http.get(environment.baseUrl +'/Administrative/GetTradeService/' + userId);
  }
  
  saveJobCategory(itemCategoryId, userId, tradeServiceId, serviceCost): Observable<any> {
    return this.http.post(environment.baseUrl +'/api/Tradesman/SaveJobCategory/'+ itemCategoryId +'/'+  userId +'/'+ tradeServiceId, serviceCost)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  saveJobCategoryForNewEntry(itemCategoryId, userId, tradeServiceId, serviceCost): Observable<any> {
    return this.http.post(environment.baseUrl +'/api/Tradesman/SaveJobCategoryForNewEntry/'+ itemCategoryId +'/'+  userId +'/'+ tradeServiceId, serviceCost)
    .pipe(
      catchError(this.errorHandler)
    )
  }
  getAllDataForCurService(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetNewTradeService/'+ userId);
  }
  getServicesCost(tradeServiceId, parentId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetServicesCost/' + tradeServiceId + '/' + parentId + '/' + userId);
  }
  getItemgategorys(): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/Itemcategory/GetPrimaryCategorys');
  }
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  getClaimedService(userId): Observable<any> {

    return this.http
    .get(environment.baseUrl + '/api/Tradesman/Getclaimedservice/' + userId, { headers: this.headers });

  }

  getNewTradeServiceForVetting(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetNewTradeServiceForVetting/');

  }
  getNewTradeServiceList(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetNewTradeServiceList/' + userId, { headers: this.headers });
  }

  getTradeServiceScrapedData(userId, tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetTradeServiceScrapedData/' + userId  + '/' + tradeServiceId);

  }

  saveClaimedData(generalData, userId): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveTradeServiceScrapedData/' + userId, generalData);
  }
  
  createNewTradeService(generalData, userId): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/CreateNewTradeService/' + userId, generalData);
  }

  createBlank(generalData, userId): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/CreateNewTradeService/' + userId, generalData);
  }
  // tslint:disable-next-line: typedef  GetTradeService
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
 
}