import { Component, OnInit } from '@angular/core';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ServicesService } from '../services.service';
import { TradeService } from 'app/main/model/tradeService';

@Component({
  selector: 'app-service-registration',
  templateUrl: './service-registration.component.html',
  styleUrls: ['./service-registration.component.scss']
})
export class ServiceRegistrationComponent implements OnInit {
    
    userId;
    tradeService;
    constructor(private servicesService: ServicesService) {
       // this.tradeService = new TradeService();
     }

    ngOnInit() {
        // this.getTradeService();
        // if(this.tradeService === undefined){
        //     this.CreateTradeServiceAccount();
        // }
    }
    disabled = false;
    getTradeService(){
        if (localStorage.getItem('user') !== null) {
            this.userId = JSON.parse(localStorage.getItem('user')).userId;
        }

        this.servicesService.getTradeServices(this.userId)
            .subscribe((response) => {
              if(response.isChecked === true){
                this.disabled = true;
              }

            this.tradeService = response;
            localStorage.setItem('tradeService', JSON.stringify(response));
        },
      );
    }

    CreateTradeServiceAccount(){
        if (localStorage.getItem('user') !== null) {
            this.userId = JSON.parse(localStorage.getItem('user')).userId;
          }

        this.servicesService.createTradeServiceAccount(this.userId)
        .subscribe((response) => {
                this.tradeService = response;
            },
            (error) => {
                alert(error);
                console.error(error);
            }
      );
    }


}
