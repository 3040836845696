import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { LoaderService } from 'app/auth/loader.service';
import { TradeService } from 'app/main/model/tradeService';
import { invalid } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { ClaimService } from '../../claimed-services/claim.service';
import { CountryConfig } from '../../../../utils/country.config';
import { ServiceSinceConfig } from '../../../../utils/service-since.config';
import { BusinessTypeConfig } from '../../../../utils/business-type.config';
import { ProvideServicesConfig } from '../../../../utils/provide-services.config';

@Component({
  selector: 'app-add-new-services.component',
  templateUrl: './add-new-services.component.html',
  styleUrls: ['./add-new-services.component.scss']
})
export class AddNewServicesComponent implements OnInit {
  countryConfig = CountryConfig;
  serviceSince = ServiceSinceConfig;
  businessType = BusinessTypeConfig;
  provideServices = ProvideServicesConfig;

  //#region Declarations

  selectedFiles?                        : FileList;
  progressInfos                         : any[] = [];
  message                               : string[] = [];
  previews                              : string[] = [];
  imageInfos?                           : Observable<any>;
  companyLogoImageUrl                   : string;
  companyCoverImageUrl                  : string;
  identityImageUrl                      : string;
  companyRegisterCertificateImageUrl    : string;
  vatCertificateImageUrl                : string;
  insuranceCertificateImageUrl          : string;

  companyLogoFile                       : File = null;
  companyCoverFile                      : File = null;
  identityFile                          : File = null;
  companyRegisterCertificateFile        : File = null;
  vatCertificateFile                    : File = null;
  insuranceCertificateFile              : File = null;

  tradeServiceDetail                    : any;
  tradeServiceId                        : any;
  formGeneral                           : FormGroup;
  formSercices                          : FormGroup;
  formServiceWeCover                    : FormGroup;
  formUploadCenter                      : FormGroup;
  tradeService
  submitted                              = false;
  disabled                               = false;
  selectedSubCategoryIds                 = []
  vettingsDoc;
  itemAllCategorys;
  itemCategories;
  itemSubCategorys
  checked: boolean;
  isValid:boolean;
  userId
  serviceWeCoverList;


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        ['bold', 'italic'],
        ['fontSize']
    ]
  };

  serviceCostData = [
    {
        isJobCategories: false, 
        itemCategory: {
          itemCategoryId: 0,
          itemCategoryName: ''
        }, 
      tradeServiceCostId: 0, 
      tradeServiceId: 0,
      serviceCost: ''
    }
  ];
  serviceCost = [
    {tradeServiceCostId: 0, 
      tradeServiceId: '', 
      itemCategoryId: '', 
      serviceCost: '', 
      isJobCategories: false
    }
  ];
  
  isImageLoading: boolean = false;
  isSercicesLoading: boolean;
  isServiceWeCoverLoading: boolean;
  isGalleryLoading:boolean
  isIdentityLoading:boolean;
  isSubmitApplicationLoading: boolean;
  //#endregion
 
  constructor(
    private activatedRoute                : ActivatedRoute,
    private router                        : Router,
    private toastr                        : ToastrService,
    private claimService                  : ClaimService,
    private formBuilder                   : FormBuilder,
    public loaderService                  : LoaderService

  ) {
    this.tradeService                     = new TradeService();
    this.checked                          = false;
    this.disabled                         = false;
    this.isServiceWeCoverLoading          = false;
    this.isSercicesLoading                = false;
    this.isGalleryLoading                 = false;
    this.isIdentityLoading                = false;
    this.isSubmitApplicationLoading       = false;
   }

  ngOnInit() {
    // this.activatedRoute.params.subscribe(
    //   (params: Params) =>{
    //     this.tradeServiceId = params['id'];
    //   }
    // )

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    this.formGeneral               = this.formBuilder.group({
      tradeServiceId               : [''],
      companyName                  : ['', [Validators.required]],
      addressLine1                 : ['', [Validators.required]],
      addressLine2                 : [''],
      country                      : ['', [Validators.required]],
      postCode                     : ['', [Validators.required]],
      cellNo                       : ['', [Validators.required]],
      landlineTelephone            : [''],
      companyWebsite               : [''], //, Validators.pattern(reg)
      numberofEmployees            : ['', [Validators.required]],
      serviceSince                 : ['', [Validators.required]],
      businessType                 : ['', [Validators.required]],
      videoLink                    : [''],
      operatingRadius              : ['', [Validators.required]],
      areasCovered                 : ['', [Validators.required]],
      identityName                 : ['', [Validators.required]],
      insuredAmount                : ['', [Validators.required]],
      expiryDate                   : ['', [Validators.required]],
      twitterLink                  : [''],
      facebookLink                 : [''],
      pinterestLink                : [''],
      serviceDescription           : ['', [Validators.required]],

    });

    this.formServiceWeCover       = new FormGroup({
      tradeServiceId              : new FormControl(),
      callOut                     : new FormControl(this.checked),
      allWorkGuaranteed           : new FormControl(this.checked),
      commercialWorkCovered       : new FormControl(this.checked),
      domesticWorkCovered         : new FormControl(this.checked),
      familyBusiness              : new FormControl(this.checked),
      freeQuotes                  : new FormControl(this.checked),
      insuranceWork               : new FormControl(this.checked),
      longEstablishedCompany      : new FormControl(this.checked),
      nhsDiscounts                : new FormControl(this.checked),
      oapDiscount                 : new FormControl(this.checked),
      overExperience              : new FormControl(this.checked),
      overTrading                 : new FormControl(this.checked),
      cardsAccepted               : new FormControl(this.checked),
      womenledBuisness            : new FormControl(this.checked),
    });

    this.formSercices             = this.formBuilder.group({
      itemCategoryId              : [''], 
    });

    this.formUploadCenter         = new FormGroup({
      companyLogoId               : new FormControl(null),
      // companyCoverId              : new FormControl(null),
      identityId                  : new FormControl(null),
      companyRegisterCertificate  : new FormControl(null),
      vatCertificate              : new FormControl(null),
      insuranceCertificate        : new FormControl(null),
    });

    // this.getVettingFile();
    // this.getTradeService();
    // this.getItemCategory();
    // this.tradeserviceDetail();
    // this.getOurService();
    // this.loadImaGegallery();
    this.GetAllDatas();
  }
  createBlank(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.claimService.createBlank(this.formGeneral.value, userId);
  }
  GetAllDatas(){
    this.userId = JSON.parse(localStorage.getItem('user')).id;
    this.claimService.getAllDataForCurService(this.userId)
    .subscribe((response: any) =>{
      if(response && response.tradeServiceId){
        this.tradeServiceId = response.tradeServiceId;
        this.tradeserviceDetail(response);
        this.getVettingFile();
        this.getItemCategory();
        this.getOurService();
        this.loadImaGegallery();
      }
    })
  }

  //#region  General Section

  // get f() { return this.formGeneral.controls; }

  saveClaimedData(){
   if(this.formGeneral.value.companyName === null || this.formGeneral.value.companyName.length < 3){
    this.toastr.error('Please input company name', 'BRP');
    return
    }
    if(this.formGeneral.value.addressLine1 === null || this.formGeneral.value.addressLine1.length < 3){
      this.toastr.error('Please input company address', 'BRP');
      return
    }
    if(this.formGeneral.value.postCode === null || this.formGeneral.value.postCode.length < 2){
      this.toastr.error('Please input your post code', 'BRP');
      return
    }
    if(this.formGeneral.value.cellNo === null || this.formGeneral.value.cellNo.length < 12){
      this.toastr.error('Please input valid mobile no', 'BRP');
      return
    }
    if(this.formGeneral.value.numberofEmployees === null || this.formGeneral.value.numberofEmployees === ''){
      this.toastr.error('Please input number of employee', 'BRP');
      return
    }
    if(this.formGeneral.value.serviceSince === null || this.formGeneral.value.serviceSince === ''){
      this.toastr.error('Please select providing service since', 'BRP');
      return
    }
    if(this.formGeneral.value.businessType === null || this.formGeneral.value.businessType === ''){
      this.toastr.error('Please select business type', 'BRP');
      return
    }

    if(this.formGeneral.value.serviceDescription === null || this.formGeneral.value.serviceDescription === ''){
      this.toastr.error('Please select serviceDescription', 'BRP');
      return
    }

    let expiryDate = Date.parse(this.formGeneral.value.expiryDate);

    if (!isNaN(expiryDate) == false) {
      this.toastr.error('Please input valid expiry date', 'BRP');
      return
    }
    let today = new Date();
    if (today > new Date(expiryDate)) {
      this.toastr.error('Please input valid expiry date', 'BRP');
      return
    }

    if(this.formGeneral.valid){
        let userId = JSON.parse(localStorage.getItem('user')).id;
        this.claimService.createNewTradeService(this.formGeneral.value, userId)
          .subscribe((response) => {
            if(this.tradeServiceId == null || this.tradeServiceId == 0){
              this.GetAllDatas();
            }
            if(response > 0){
              for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
                if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == 'Services') 
                  {
                      (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
                  }
                }
              }

            },
            (error) => { //Error callback
              alert(error);
            }
          );
      }
  }


  tradeserviceDetail(responce) {
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.tradeServiceDetail = responce;
    this.formGeneral.patchValue(responce);
    if (responce.isChecked === true) {
      this.formGeneral.disable();
      this.disabled = true;
    }

    // for job category
    this.serviceCostData = [];
    this.claimService
      .getServicesCost(responce.tradeServiceId, 0, userId)
      .subscribe((response) => {
        this.serviceCostData = response;
        let primaryCategory = {"itemCategoryId":  this.tradeServiceDetail.itemCategoryId};
        if(this.serviceCostData.length === 0 && primaryCategory &&  primaryCategory.itemCategoryId){
          this.getItemSubCategory(primaryCategory);
        }
        for (let o of this.serviceCostData) {
          if (o.isJobCategories === true) {
            this.selectedSubCategoryIds.push(o.itemCategory.itemCategoryId)
          }
        }
      },
      );
      
    if( this.tradeServiceDetail){
      this.formSercices.patchValue({
        itemCategoryId: responce.itemCategoryId
      })
    }

  }

  // getTradeService2() {
  //   if (localStorage.getItem('user') !== null) {
  //     this.userId = JSON.parse(localStorage.getItem('user')).userId;
  //   }

  //   this.claimService.getTradeServices(this.userId)
  //     .subscribe((response) => {
  //       if (response.isChecked === true) {
  //         this.formGeneral.disable();
  //         this.disabled = true;
  //       }
  //       this.tradeService = response;
  //       this.formGeneral.patchValue(this.tradeService);
  //     },
  //   );
  // }


  //#endregion


  //#region Services

  saveOurService(tabName: string) {
    if(this.formServiceWeCover.value.allWorkGuaranteed === false &&
      (this.formServiceWeCover.value.callOut === false) &&
      (this.formServiceWeCover.value.cardsAccepted  === false) &&
      (this.formServiceWeCover.value.commercialWorkCovered  === false) &&
      (this.formServiceWeCover.value.domesticWorkCovered  === false) &&
      (this.formServiceWeCover.value.familyBusiness  === false) &&
      (this.formServiceWeCover.value.freeQuotes === false) &&
      (this.formServiceWeCover.value.insuranceWork  === false) &&
      (this.formServiceWeCover.value.longEstablishedCompany  === false) &&
      (this.formServiceWeCover.value.nhsDiscounts  === false) &&
      (this.formServiceWeCover.value.oapDiscount === false) &&
      (this.formServiceWeCover.value.overExperience === false) &&
      (this.formServiceWeCover.value.overTrading === false) &&
      (this.formServiceWeCover.value.womenledBuisness === false)){
        this.toastr.error('Please select at-least one service', 'BRP');
        return
      }
      
    this.formServiceWeCover.value.tradeServiceId = this.tradeServiceDetail.tradeServiceId
    this.formServiceWeCover.value.userId = JSON.parse(localStorage.getItem('user')).id;
   
    this.isServiceWeCoverLoading = true;
    this.claimService.saveOurService(this.formServiceWeCover.value)
      .subscribe((response) => {
        if(response === 200){
          this.toastr.success('Information saved successfully', 'BRP');
          this.isServiceWeCoverLoading = false;
          }
        },
        (error) => {
          console.log(error);
          this.isServiceWeCoverLoading = false;
        }
      );
      
  }
  //ourServices = new OurServices();
  getOurService(){

    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.claimService.getOurServicesForNewTradeService(userId, this.tradeServiceId)
        .subscribe((response) => {
          this.serviceWeCoverList = response[0];

            if(this.serviceWeCoverList !== undefined){
              this.formServiceWeCover.patchValue({
                callOut						        : this.serviceWeCoverList.callOut,
                allWorkGuaranteed				  : this.serviceWeCoverList.allWorkGuaranteed,
                commercialWorkCovered			: this.serviceWeCoverList.commercialWorkCovered,
                domesticWorkCovered			  : this.serviceWeCoverList.domesticWorkCovered,
                familyBusiness				    : this.serviceWeCoverList.familyBusiness,
                freeQuotes					      : this.serviceWeCoverList.freeQuotes,
                insuranceWork					    : this.serviceWeCoverList.insuranceWork,
                longEstablishedCompany		: this.serviceWeCoverList.longEstablishedCompany,
                nhsDiscounts					    : this.serviceWeCoverList.nhsDiscounts,
                oapDiscount					      : this.serviceWeCoverList.oapDiscount,
                overExperience				    : this.serviceWeCoverList.overExperience,
                overTrading					      : this.serviceWeCoverList.overTrading,
                cardsAccepted					    : this.serviceWeCoverList.cardsAccepted,
                womenledBuisness				  : this.serviceWeCoverList.womenledBuisness,
                })
            }
          
          },
          (error) => {
            alert(error);
          }
      );
  }

  saveService() {
    this.serviceCost = [];
    
    let userId = JSON.parse(localStorage.getItem('user')).id;
    if(this.selectedSubCategoryIds.length === 0){
      this.toastr.error('Please select at-least one additional service', 'BRP');
        return
    }

    for (var i = 0; i < this.selectedSubCategoryIds.length; i++) {
      var serviceCost = (<HTMLInputElement>document.getElementById(this.selectedSubCategoryIds[i]))
      
      var serviceCostValue
      if (serviceCost != null) {
        serviceCostValue = serviceCost.value;
        if(serviceCost.value === ''){
          this.toastr.error('Please provide estimated services cost');
          return
        }
      }
      else {
        serviceCostValue = null;
      }
      
      this.serviceCost.push(
        {
          tradeServiceCostId: 0,
          tradeServiceId: this.tradeServiceDetail.tradeServiceId, 
          itemCategoryId: this.selectedSubCategoryIds[i],
          serviceCost: serviceCostValue,
          isJobCategories: false
        }
      );
    }
    this.isSercicesLoading = true;
    
    this.claimService.saveJobCategoryForNewEntry(this.formSercices.value.itemCategoryId, userId, this.tradeServiceId, this.serviceCost)
      .subscribe((response) => {
        if(response === 200){
          for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
            if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == 'Upload Center') 
              {
                  (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
              }
            }
          }
          this.isSercicesLoading = false;
        },
        (error) => { //Error callback
          this.toastr.error(error, 'Something wrong');
          this.isSercicesLoading = false;
        }
      );
      
  }

  getItemSubCategory(value): void {
    this.selectedSubCategoryIds = [];
    this.serviceCostData = [];
    
    this.itemSubCategorys = this.itemAllCategorys
    .filter(i => i.parentId === value.itemCategoryId);
    
    for(let o of this.itemSubCategorys){
      this.serviceCostData.push(
        {
          isJobCategories: false, 
            itemCategory: {
            itemCategoryId: o.itemCategoryId,
            itemCategoryName: o.itemCategoryName
          }, 
        tradeServiceCostId: 0, 
        tradeServiceId: 0,
        serviceCost: ''
        }
      );
   }
  }

  jobCategoryCheck(itemCategoryId){
    const index: number = this.selectedSubCategoryIds.indexOf(itemCategoryId);
    if (index !== -1) {
        this.selectedSubCategoryIds.splice(index, 1);
    }  
    else{
      this.selectedSubCategoryIds.push(itemCategoryId)
    }
  }

  getItemCategory(): void {
    this.claimService.getItemgategorys().subscribe(x => {
      this.itemAllCategorys = x;
      this.itemCategories =  x.filter(i => i.parentId === 0);
    });
  }

  //#endregion


  //#region Upload Center

  saveVettingStatus() {
    this.userId = JSON.parse(localStorage.getItem('user')).id;

    const formData = new FormData();
    formData.append('userId', this.userId);
    formData.append('tradeServiceId', this.tradeServiceId);
    formData.append('CompanyLogoFile', this.companyLogoFile);
    formData.append('companyCoverFile', this.companyCoverFile);
    formData.append('IdentityFile', this.identityFile);
    formData.append('CompanyRegisterCertificateFile', this.companyRegisterCertificateFile);
    formData.append('VATCertificateFile', this.vatCertificateFile);
    formData.append('InsuranceCertificateFile', this.insuranceCertificateFile);
    
    this.isIdentityLoading = true;
    this.claimService.saveVettingStatusForNewTradeService(formData)
      .subscribe((response) => {
        if(response === 200){
          for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
            if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == 'Proposal') 
              {
                  (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
              }
            }
          }
          this.isIdentityLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  deleteImageBox(image): void {
    this.identityImageUrl = '';
    this.identityFile = null;
    // switch (image) {
    //   case 'accreditation1ImageUrl':
    //     this.accreditation1ImageUrl ='assets/images/default-placeholder.png';
    //     this.accreditationContainer.push('accreditation1File');
    //       break;

    //   case 'accreditation2ImageUrl':
    //     this.accreditation2ImageUrl ='';
    //     this.accreditationContainer.push('accreditation2File');
    //       break;

    //   case 'accreditation3ImageUrl':
    //    this.accreditation3ImageUrl ='';
    //     this.accreditationContainer.push('accreditation3File');
    //       break;
          
    //   default:
    //       break;
    // }
  }

  getVettingFile(){
    this.userId = JSON.parse(localStorage.getItem('user')).id;

    this.claimService.getVettingFileByUserId(this.userId, this.tradeServiceId)
        .subscribe((response) => {
          this.vettingsDoc = response;
          
          this.vettingsDoc.forEach(x=> {
            if(x.vettingHead === 'CompanyLogoFile')
            {
             this.companyLogoImageUrl = x.vettingValueofHead
            }
            if(x.vettingHead === 'companyCoverFile')
            {
             this.companyCoverImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'IdentityFile')
            {
             this.identityImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'CompanyRegisterCertificateFile')
            {
              this.companyRegisterCertificateImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'VATCertificateFile')
            {
              this.vatCertificateImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'InsuranceCertificateFile')
            {
              this.insuranceCertificateImageUrl = x.vettingValueofHead
            }
          }); 
      },
    );
  }

  onCompanyLogoFile(fileInput: any) {
    this.companyLogoFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.companyLogoImageUrl = reader.result as string;
      };
    }
  }

  onCompanyCoverFile(fileInput: any) {
    this.companyCoverFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.companyCoverImageUrl = reader.result as string;
      };
    }
  }

  onIdentityFile(fileInput: any) {
    this.identityFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.identityImageUrl = reader.result as string;
      };
    }
  }

  onCompanyRegisterCertificateFile(fileInput: any) {
    this.companyRegisterCertificateFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.companyRegisterCertificateImageUrl = reader.result as string;
      };
    }
  }

  onVatCertificateFile(fileInput: any) {
    this.vatCertificateFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.vatCertificateImageUrl = reader.result as string;
      };
    }
  }

  onInsuranceCertificateFile(fileInput: any) {
    this.insuranceCertificateFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.insuranceCertificateImageUrl = reader.result as string;
      };
    }
  }
  
  saveImaGegallery() {
    this.selectedFiles
    
    const formData = new FormData();
    // for (let i = 0; i < this.selectedFiles.length; i++) {
    //   const file = this.selectedFiles[i];
    //   formData.append('images[]', file, file.name);
    // }
    formData.append('userId', this.userId);
    formData.append('tradeServiceId', this.tradeServiceId);

    for (let i = 1; i < this.selectedFiles.length + 1; i++) {
      formData.append('File' + i, this.selectedFiles[i - 1]);
    }

    if(this.selectedFiles.length === 0){
      this.toastr.error('Please select images', 'BRP');
      return
    }
    this.isGalleryLoading = true;
    this.claimService.saveImaGegallery(formData)
      .subscribe((response) => {//Next callback
        if(response === 200){
          this.toastr.success('Information saved successfully');
          this.isGalleryLoading = false;
          }
      },
        (error) => {
          this.toastr.success(error.error);
          console.log(error);
          this.isGalleryLoading = false;
        }
      );

      
  }
  
  imgUrl = "https://tvs-building-development.s3.eu-west-2.amazonaws.com/";
  loadImaGegallery(): void {
    let userId = JSON.parse(localStorage.getItem('user')).id;

    this.claimService.loadImaGegallery(userId, this.tradeServiceId)
    .subscribe((response) => {//Next callback

        this.message = [];
        this.progressInfos = [];
        this.selectedFiles = response;
        
        this.previews = [];
        if (response && response[0]) {
          const numberOfFiles = response.length;
          for (let i = 0; i < numberOfFiles; i++) {
            
            const reader = new FileReader();

            // reader.onload = (e: any) => {
            //   this.previews.push(this.imgUrl + response[i].resourceFile);
            // };
            this.previews.push(this.imgUrl + response[i].resourceFile);
            // if(response[i]){
            //   // reader.readAsDataURL(this.imgUrl + response[i].resourceFile);
            // }
            
          }
        }

      },
      (error) => {
        console.log(error);
      }
    );


    // this.message = [];
    // this.progressInfos = [];
    // this.selectedFiles = event.target.files;

    // this.previews = [];
    // if (this.selectedFiles && this.selectedFiles[0]) {
    //   const numberOfFiles = this.selectedFiles.length;
    //   for (let i = 0; i < numberOfFiles; i++) {
    //     const reader = new FileReader();

    //     reader.onload = (e: any) => {
    //       this.previews.push(e.target.result);
    //     };

    //     reader.readAsDataURL(this.selectedFiles[i]);
    //   }
    // }
  }

  selectFiles(event: any): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;

    this.previews = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        
        reader.readAsDataURL(this.selectedFiles[i]);
        this.isImageLoading = false;
      }
    }
  }
  
  //#endregion


  //#region Proposal

  submitApplication() {
    this.userId = JSON.parse(localStorage.getItem('user')).id;

    this.isSubmitApplicationLoading = true;
    this.claimService.goVettingForNewTradeService(this.userId, this.tradeServiceId)
      .subscribe((response) => {
        if (response === true) {
          this.toastr.success('Your information transferred for vetting', 'BRP');
          this.isSubmitApplicationLoading = false;
          this.router.navigate(['/claimed-service']); 
        }
        else {
          this.isValid = true;
          this.toastr.info('Please input the required information below.', 'BRP');
          this.isSubmitApplicationLoading = false;
        }
      },
        (error) => { //Error callback
          this.isSubmitApplicationLoading = false;
          alert(error);
        }
      );




    //// window.location.reload();
  }

  //#endregion


}

export class OurServices {

  allWorkGuaranteed       : false;
  callOut                 : false;
  cardsAccepted           : false;
  commercialWorkCovered   : false;
  domesticWorkCovered     : false;
  familyBusiness          : false;
  freeQuotes              : false;
  insuranceWork           : false;
  longEstablishedCompany  : false;
  nhsDiscounts            : false;
  oapDiscount             : false;
  ourServicesId           : 0;
  overExperience          : false;
  overTrading             : false;
  tradeService            : null;
  tradeServiceId          : 0;
  womenledBuisness        : false
}