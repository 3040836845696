import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

// import { AuthService } from 'app/auth/auth.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  // idToken = JSON.parse(localStorage.getItem("user"));
  // req = new HttpHeaders();
  
  constructor(private http: HttpClient,
    // private authService   : AuthService
    ) { }

  postFile(formData): Observable<any> {
    return this.http.post(`${environment.baseUrl}` + '/Administrative/uploadFile/', formData);   
  }

  getMyProfileById(myProfileId): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/Administrative/GetMyProfileById/' + myProfileId)
    .pipe(
      catchError(this.errorHandler)
    );
  }

  getMyProfileUserId(userId): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/Administrative/GetMyProfileByUserId/' + userId);
  }
  
  savemyProfile(myProfileDto): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/Administrative/SaveMyProfile', myProfileDto);
  }

  updateProfile(myProfile): Observable<any> {
    return this.http
    .post(`${environment.baseUrl}` + '/Administrative/UpdateProfile', myProfile);

  }
  
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
 
}
