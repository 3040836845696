import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-vetting-reject',
  templateUrl: './vetting-reject.component.html',
  styleUrls: ['./vetting-reject.component.scss']
})
export class VettingRejectComponent implements OnInit {

  form: FormGroup;
  tradeServiceId;
  isSubmitApplicationLoading: boolean;
  userId

  constructor(private formBuilder: FormBuilder,
    private router            : ActivatedRoute,
    private vettingService    : VettingService,
    private route             : Router,
    private toastr            : ToastrService) { 
      this.isSubmitApplicationLoading       = false;
    }

  ngOnInit() {
    this.router.params.subscribe(
      (params: Params) =>{
        this.tradeServiceId = params['id'];
        this.userId = params['userId'];
      }
    )
    this.createVettingRejectForm();
  }

  createVettingRejectForm(){
    this.form = this.formBuilder.group({
      description		           : ['', [Validators.required]],
      attachment			         : [null],
    });
  }
  
  rejectForClaimedTradesperson(){

    this.isSubmitApplicationLoading = true;
    let description = this.form.value.description;
    this.vettingService.rejectTradeService(this.tradeServiceId, description, this.userId)
        .subscribe((response) => {
          if(response === 200){
            this.isSubmitApplicationLoading = false;
            this.toastr.success('Tradesperson rejected successfully', 'BRP');
            this.route.navigate(['claimed-tradesperson']);
          }
     },
     (error) => { //Error callback
      this.isSubmitApplicationLoading = false;
    }
    );
  }
}
