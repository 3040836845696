import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { FuseSharedModule } from '@fuse/shared.module';

import { FaqService } from 'app/main/pages/faq/faq.service';
import { FileListComponent } from './file-list.component';
import { MatButtonModule, MatProgressBarModule, MatRippleModule, MatSlideToggleModule, MatTableModule } from '@angular/material';

const routes = [
    { path      : 'file', component: FileListComponent},
];

@NgModule({
    declarations: [
        FileListComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatProgressBarModule,
        MatButtonModule,
        MatIconModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatTableModule,
        
        MatExpansionModule,
        MatIconModule,

        FuseSharedModule
    ],
    providers   : [
        FaqService
    ]
})
export class FileModule
{
}

