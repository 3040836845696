import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserModel } from 'app/main/model/userModel';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-rule-mapper-list',
  templateUrl: './user-rule-mapper-list.component.html',
  styleUrls: ['./user-rule-mapper-list.component.scss']
})
export class UserRuleMapperListComponent implements OnInit {
  
  // materialList: any;
  dataSource: MatTableDataSource<any>;
  userList: UserModel
  userRuleMapper: any;
  leftNavigation: any;
  isLoading: boolean = false;
  userRuleMapperListColumns: string[] = ['position', 'name','rule','edit'];

  constructor(private userService: UserService,
              private router: Router) {
                this.dataSource = new MatTableDataSource();
              }

  ngOnInit(): void {
    this.reloadData();
    // this.leftnavigation();
  }

           
  reloadData(): void {
    this.isLoading = true;
    this.userService.getUserRuleMapper().subscribe(x => {
      this.userRuleMapper =  x;
      this.isLoading = false;
    });
  }

  deleteMaterial(id: number): void {}

  materialDetails(userRule: any): void{
    // this.router.navigate(['user-rule-mapper', id]);
    // this.router.navigate([`${link.split('?')[0]}`, {queryParams: {id: id, username: 'jimmy'}}]);
    this.router.navigate(['/user-rule-mapper'], { queryParams: { username: userRule.user.userName , ruleid: userRule.userRule.userRuleId} });
  }

}
