import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClaimService } from 'app/main/tradesperson/services/claimed-services/claim.service';
import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-add-notification',
  templateUrl: './add-notification.component.html',
  styleUrls: ['./add-notification.component.scss']
})
export class AddNotificationComponent implements OnInit {

  form: FormGroup;
  tradeServiceId;
  isSubmitApplicationLoading: boolean;
  userId
  expiryDate
  insuredAmount
  companyName

  constructor(private formBuilder: FormBuilder,
    private router            : ActivatedRoute,
    private vettingService    : VettingService,
    private route             : Router,
    private toastr            : ToastrService) { 
      this.isSubmitApplicationLoading       = false;
    }

  ngOnInit() {
    this.router.params.subscribe(
      (params: Params) =>{
        this.tradeServiceId = params['tradeServiceId'];
        this.insuredAmount = params['insuredAmount'];
        this.expiryDate = params['expiryDate'];
        this.companyName = params['companyName'];

        this.userId = params['userId'];
      }
    )
    this.createVettingRejectForm();
  }

  createVettingRejectForm(){
    this.form = this.formBuilder.group({
      notificationText		           : ['', [Validators.required]],
      tradeServiceId			         : [this.tradeServiceId],
    });
  }
  
  addNotification(){

    this.isSubmitApplicationLoading = true;
    let description = this.form.value.description;
    this.vettingService.addNotification(this.form.value)
        .subscribe((response) => {
          this.isSubmitApplicationLoading = false;
            this.toastr.success('Notification added successfully', 'BRP');
            this.route.navigate(['add-notification']);
     },
     (error) => { //Error callback
      this.isSubmitApplicationLoading = false;
    }
    );
  }
}
