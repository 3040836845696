import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { ResetPasswordService } from './reset-password.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { fuseAnimations } from '@fuse/animations';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector     : 'reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy
{
    resetPasswordForm: FormGroup;
    userId;
    userName;
    email;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService  : FuseConfigService,
        private _formBuilder        : FormBuilder,
        private toastr              : ToastrService,
        private router              : Router,
        private route               : ActivatedRoute,
        private resetPasswordService:ResetPasswordService
    )
    {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this._unsubscribeAll = new Subject();

        this.route.params.subscribe(params => {
            this.userId = (params.Id);
          });
    }
    
    ngOnInit(): void
    {
        this.resetPasswordForm = this._formBuilder.group({
            name           : [''],
            email          : [''],
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });

        this.getUserDetail();
    }

    changePassword() {
        if (this.resetPasswordForm.value.password === ''){
          this.toastr.error('Please inpute your password');
          return;
        }
        const user = {
          email: this.email,
          password: this.resetPasswordForm.value.password
        };
        this.resetPasswordService.change(user)
          .subscribe(
            data => {
              if (data === 200){
                this.toastr.success('password reset successfully');
                this.router.navigate(['/']);
              }
              else{
                this.toastr.error('Something is Wrong');
              }
            },
            error => {
              this.toastr.error(error, 'Something is Wrong');
            });
    }

    getUserDetail(): void {
        this.resetPasswordService.changePassword(this.userId)
          .subscribe(
            data => {
              if(data.userId === 0){
                this.router.navigate(['/page-not-found']);
              }
              if (data !== null){
                this.userName = data.userName;
                this.email = data.email;
              }
              else{
                this.toastr.error('User not exists');
              }
    
            },
            error => {
              this.toastr.error(error, 'Something is Wrong');
            });
    }
    
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {passwordsNotMatching: true};
};
