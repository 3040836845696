import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClaimService } from '../claim.service';

@Component({
  selector: 'app-claimed-service-list',
  templateUrl: './claimed-service-list.component.html',
  styleUrls: ['./claimed-service-list.component.scss']
})
export class ClaimedServiceListComponent implements OnInit {
  ratingListColumns: string[] = ['position', 'cellNo',  'companyName', 'addressLine1',  'businessType', 'tradeServiceReviewsId'];

 
  // newTradeserviceListColumns: string[] = ['position', 'cellNo',  'companyName', 'addressLine1', 'businessType', 'tradeServiceReviewsId'];

  isLoading: boolean = false;
  isLoading2: boolean = false;

  constructor(
      private router: Router,
      private toastr            : ToastrService,
      private claimService: ClaimService,
  ) {}
  tradeServiceList: any;
  newTradeServiceList: any;
  tradeServiceDetail: any;
  ngOnInit(): void {
      this.getClaimedService();
      this.getNewTradeServiceList();
  }
  
  getClaimedService(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.isLoading = true;
    this.claimService.getClaimedService(userId).subscribe(responce => {
      this.isLoading = false;
      this.tradeServiceList = responce;
    });
  }

  getNewTradeServiceList(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.isLoading2 = true;
    this.claimService.getNewTradeServiceList(userId).subscribe(responce => {
      this.isLoading2 = false;
      this.newTradeServiceList = responce;
    });
  }


  registerNow(tradeServiceId){

    this.router.navigate(['/claimed-service-registration', tradeServiceId]);
    
    // let userId = JSON.parse(localStorage.getItem('user')).id;

    // this.claimService.getTradeServiceScrapedData(userId, tradeServiceId).subscribe(
    //   responce => {
    //     this.tradeServiceDetail = responce;


    //     this.router.navigate(['/claimed-service-registration', tradeServiceId]);
    //   },
    //   error => {
    //     this.toastr.error(error.error.message, '', {
    //       positionClass: 'toast-top-center',
    //     });
    //   }
    // );
  }

  addNewService(){
    this.router.navigate(['/add-new-services']);
  }
}

