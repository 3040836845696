import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../../main/model/user'
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    public isLogedIn: boolean = false;
    constructor(
        private jwtHelper:JwtHelperService,
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    isAuthenticated():boolean{
        const token = localStorage.getItem('token');
        return !this.jwtHelper.isTokenExpired(token);
      }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public getHeaders() {
        if(localStorage.getItem('_authToken')){
          return new HttpHeaders({
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("_authToken")
          });
        }
        return new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("_guestToken")
        });
    }

    // login(email, password1) {
    //     const user = {email: email, password: password1};
    //     const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'};

    //     return this.http.post<any>(environment.baseUrl + '/api/auth/adminPanelLogIn', user)
    //         .pipe(map(user => {
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             // GlobalConstants.userAccount = {userId:user.userId, userName:user.userName, email:user.email, userRuleId:user.userRuleId, userRuleName: user.userRuleName};
    //             localStorage.setItem('user', JSON.stringify(user));
    //             this.userSubject.next(user);
    //             return user;
    //         }));
    // }
    isUserLoggedIn(){
       return this.isLogedIn;
    }
    login(email, password1) {
        const user = {email: email, password: password1};
        return this.http
          .post<any>(environment.baseUrl + '/api/auth/adminPanelLogIn', user)
          .pipe(map(user => {

                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        // GlobalConstants.userAccount = {userId:user.userId, userName:user.userName, email:user.email, userRuleId:user.userRuleId, userRuleName: user.userRuleName};
                        localStorage.setItem('user', JSON.stringify(user));

                        localStorage.setItem('token', user.token);
                        this.isLogedIn = true;
                       // this.userSubject.next(user);
                       // return user;
                    }));
                    
      }


    getToken() {
        return localStorage.getItem('token');
    }


    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        // this.router.navigate(['/account/login']);
        this.router.navigate(['/']);
    }

    register(user: User) {
        return this.http.post(`${environment.baseUrl}/User/Register`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.baseUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.baseUrl}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.baseUrl}/User/Update/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.baseUrl}/User/Delete/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }
}