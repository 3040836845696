import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TradeService } from 'app/main/model/tradeService';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../services.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  tradeService;
  form: FormGroup;
  demo1TabIndex;
  userId                                   : any;
  companyLogoFile                          : File = null;
  companyLogoImageUrl                      : string;
  filteredOptions                          : Observable<string[]>;
  submitted                                 = false;
  myControl                                 = new FormControl();
  options                                   = [];
  
  disabled = false;

  constructor(private servicesService   : ServicesService,
              private formBuilder       : FormBuilder,
              private toastr            : ToastrService) {
    this.tradeService = new TradeService();
    this.form         = this.createGeneralForm();
    
   }

  ngOnInit() {
   this.getPostalTowns();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      
    this.getTradeService();
  }

  private _filter(value: string): string[] {
    const filterValue = value;
    return this.options.filter(option => option.townName.toLowerCase().includes(filterValue));
  }

  onImgError(event) { 
    event.target.src = '../../../../../../assets/images/logos/cropped-logo-dummy.png';
  }

  getPostalTowns(){
    this.servicesService.getPostalTowns()
    .subscribe((response) => {
      this.options = response;
    },
      (error) => {
        alert(error);
      }
    );
  };

  getTradeService(){
    if (localStorage.getItem('user') !== null) {
        this.userId = JSON.parse(localStorage.getItem('user')).userId;
      }
      
    this.servicesService.getTradeServices(this.userId)
        .subscribe((response) => {
          if(response.isChecked === true){
            this.form.disable();
            this.disabled = true;
          }
          if(response.companyLogo !== null)
          {
            this.companyLogoImageUrl = 'https://tvs-building-development.s3.eu-west-2.amazonaws.com/' + response.companyLogo;
            response.companyLogo = '';
          }
          this.form.patchValue(response);
         this.myControl.patchValue(response.postalTown);
      },
    );
  }

  onCompanyLogoFile(fileInput: any) {
    this.companyLogoFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.companyLogoImageUrl = reader.result as string;
      };
    }
  }

  get f() { return this.form.controls; }
  createGeneralForm(): FormGroup{
    return this.formBuilder.group({
      tradeServiceId      : [this.tradeService.tradeServiceId, [Validators.required]],
      cellNo			        : [this.tradeService.cellNo, [Validators.required, Validators.pattern("^((\\+44-?)|0)?[0-9]{12}$")]],
      addressLine1		    : [this.tradeService.addressLine1, [Validators.required, Validators.minLength(6)]],
      addressLine2		    : [this.tradeService.addressLine2],
      businessType		    : [this.tradeService.businessType, [Validators.required]],
      companyName		      : [this.tradeService.companyName, [Validators.required, Validators.minLength(6)]],
      companyWebsite		  : [this.tradeService.companyWebsite],
      country			        : [this.tradeService.country, [Validators.required]], // , [Validators.required]
      facebookLink		    : [''],
      numberofEmployees		: [this.tradeService.numberofEmployees, [Validators.required]],
      pinterestLink		    : [''],
      postCode			      : [this.tradeService.postCode, [Validators.required]],
      postalTown		      : [this.tradeService.postalTown],
      serviceSince		    : [this.tradeService.serviceSince, [Validators.required]],
      twitterLink		      : [''],
      companyLogo		      : [this.tradeService.companyLogo],
      landlineTelephone		: [this.tradeService.landlineTelephone],
      userId		          : [this.tradeService.userId]
    });
  }
  
  saveGeneralInformation(tabName: string) {
    if(this.form.value.companyName === null || this.form.value.companyName === ''){
      this.toastr.success('Please input company name', 'BRP');
      return
    }
    if(this.form.value.addressLine1 === null || this.form.value.addressLine1 === ''){
      this.toastr.success('Please input your company address', 'BRP');
      return
    }
    if(this.form.value.postCode === null || this.form.value.postCode === ''){
      this.toastr.success('Please input your post code', 'BRP');
      return
    }
    if(this.form.value.cellNo === null || this.form.value.cellNo === ''){
      this.toastr.success('Please input your mobile number.', 'BRP');
      return
    }

    this.submitted = true;
    if (this.form.invalid) {
        return;
    }

    if (localStorage.getItem('user') !== null) {
      this.form.value.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    const formData = new FormData();
    formData.append('cellNo', this.form.value.cellNo);
    formData.append('addressLine1', this.form.value.addressLine1);
    formData.append('addressLine2', this.form.value.addressLine2 === 'null' ? '': this.form.value.addressLine2);
    formData.append('businessType', this.form.value.businessType);
    formData.append('companyName', this.form.value.companyName);
    formData.append('companyWebsite', this.form.value.companyWebsite);
    formData.append('country', this.form.value.country);
    formData.append('facebookLink', this.form.value.facebookLink === 'null' ? '': this.form.value.facebookLink);
    formData.append('numberofEmployees', this.form.value.numberofEmployees === 'null' ? '': this.form.value.numberofEmployees);
    formData.append('pinterestLink', this.form.value.pinterestLink === 'null' ? '': this.form.value.pinterestLink);
    formData.append('postCode', this.form.value.postCode);
    formData.append('postalTown', this.myControl.value);
    formData.append('serviceSince', this.form.value.serviceSince);
    formData.append('twitterLink', this.form.value.twitterLink === 'null' ? '': this.form.value.twitterLink);
    formData.append('companyLogo', this.companyLogoFile);
    formData.append('landlineTelephone', this.form.value.landlineTelephone === 'null' ? '': this.form.value.landlineTelephone);

    formData.append('userId', this.form.value.userId);

    this.servicesService.saveGeneralData(formData)
      .subscribe((response) => {//Next callback
        if(response === 200){
          for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
          if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
            {
                (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
            }
          }
        }
        },
        (error) => { //Error callback
          alert(error);
        }
      );
  }
}
