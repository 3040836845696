import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { ServicesService } from '../../services.service';
import { ToastrService } from 'ngx-toastr';
import { TradeService } from 'app/main/model/tradeService';
import { of } from 'rxjs';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  userId
  disabled = false;
  isValid:boolean;

  constructor(private servicesService: ServicesService,
    private formBuilder: FormBuilder,
    private toastr            : ToastrService,) { 
      this.isValid = false;
    }


  ngOnInit(): void {
  }

  submitApplication(){
  if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
  }
  this.servicesService.goVetting(this.userId)
    .subscribe((response) => {
      if(response === true){
        this.toastr.success('Your information transferred for vetting', 'BRP');
        window.location.reload();
      }
      else{
        this.isValid = true;
        this.toastr.info('Please input the required information below.', 'BRP');
      }
      },
      (error) => { //Error callback
        alert(error);
      }
    );
   // window.location.reload();
  }
}