import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserModel } from 'app/main/model/userModel';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'app/auth/confirmed.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userId: number;
  oldPassword: string = '';
  newPassword: string= '';
  confirmPassword: string= '';

  passwordIsValid: any;
  submitted = false;
  signUpForm: FormGroup;
  isLoading: boolean = false;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public fb: FormBuilder,
    private userService: UserService,) {}

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      password          : ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword   : ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  passwordValid(event: any): void {
    this.passwordIsValid = event;
  }

  get f() {
    return this.signUpForm.controls;
  }

  submit(): void{
    this.submitted = true;
    if (!this.passwordIsValid){
      this.toastr.warning('Weak & Average password not accepted','Good or Strong password required', {
        positionClass: 'toast-top-center',
      });
      
      return;
    }
    if (this.signUpForm.invalid) {
      return;
    }


    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).email;
    }
    if(this.signUpForm.value.password === this.signUpForm.value.confirmPassword){

      this.isLoading = true;
      this.userService.resetPassword(this.userId, this.signUpForm.value.password)
      .subscribe(
        (response) => {
          
          if (response !== null){
            this.toastr.success(response.message, 'TVS');
            this.isLoading = false;
          }
          else{
            this.toastr.error('Error occured to changine password', 'TVS');
          }
        },
        (error) => {
          
        }
      );


    }
    else{
      this.toastr.error('Password not matched', 'TVS');
    }
    
  }
}
