import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { DocumentViewerComponent } from '../document-viewer/document-viewer.component';
import { MatDialog } from '@angular/material/dialog';
import { VettingService } from '../vetting.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-vetting-create',
  templateUrl: './vetting-create.component.html',
  styleUrls: ['./vetting-create.component.scss']
})
export class VettingCreateComponent implements OnInit {

  id: number;
  buttonText: string;
  vettingStatus: any;
  companyName: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _matDialog: MatDialog,
    private vettingService: VettingService) {
     }


  downloadFile(id){
    this.router.navigate([environment.baseUrl + '/Administrative/DownloadFile?fileName=637483997844273629.png']);

  }
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.vettingService.getVettingStatus(this.id)
      .subscribe(data => {
        if(data.length >0 ){
          this.vettingStatus = data;
        }
      }, error => console.log(error));

    if (this.id === undefined){
      this.buttonText        = 'Save';
    }else{
      this.buttonText        = 'Update'; 
    }
  }

  submit(): void{}

  updateStatus(vattingId): void
    {
        this._matDialog.open(DocumentViewerComponent, {
            panelClass: 'knowledgebase-article-dialog',
            data      : {vattingId}
        });
    }

    
  checked(vattingId){}

}
