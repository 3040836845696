import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimService } from 'app/main/tradesperson/services/claimed-services/claim.service';
import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  ratingListColumns: string[] = ['position', 'companyName', 'identityName', 'insuredAmount', 'expiryDate', 'notificationId'];
  isLoading: boolean = false;
  constructor(
      private router: Router,
      private toastr            : ToastrService,
      private vettingService: VettingService,
  ) {}
  notificationList: any;
  ngOnInit(): void {
      this.getNotification();
  }
  
  getNotification(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.isLoading = true;
    this.vettingService.getNotification(userId).subscribe(responce => {
      this.isLoading = false;
      this.notificationList = responce;
    });
  }
  
  checkDetail(notificationId){
    this.router.navigate(['/notification-details', notificationId]);
  }
}

