import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimService } from 'app/main/tradesperson/services/claimed-services/claim.service';
import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  ratingListColumns: string[] = ['position', 'cellNo',  'companyName', 'addressLine1',  'businessType', 'tradeServiceReviewsId'];


  waitingListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo', 'claimed', 'Detail'];
  approvedListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo', 'claimed'];

  newTradeServiceaitingListColumns: string[] = ['position', 'companyName', 'addressLine1', 'cellNo', 'Detail'];
  newTradeServiceApprovedListColumns: string[] = ['position', 'companyName', 'insuredAmount', 'expiryDate', 'cellNo', 'businessType', 'tradeServiceReviewsId'];

  isLoading: boolean = false;
  isLoading2: boolean = false;

  constructor(
      private router: Router,
      private toastr            : ToastrService,
      private claimService: ClaimService,
      private vettingService: VettingService,
  ) {}
  terviceForNotification: any;

  ngOnInit(): void {
      this.loadNewTradeServiceData();
  }
  
  loadNewTradeServiceData(): void {
    this.isLoading = true;
    this.vettingService.getTradeServiceForNotification().subscribe(response => {
      
      this.isLoading = false;
      this.terviceForNotification = response;

    });
  }
  
  checkDetail(tradeServiceId, insuredAmount, expiryDate, companyName){

    this.router.navigate(['/notification-entry', tradeServiceId, insuredAmount, expiryDate, companyName]);
  }
  
}

