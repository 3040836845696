import 'hammerjs';

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { RouterModule, Routes } from '@angular/router';

import { AdministrativeModule } from './main/administrative/administrative.module';
import { AppComponent } from 'app/app.component';
import { AppsModule } from './main/apps/apps.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginComponent } from './main/pages/authentication/login/login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { PagesModule } from './main/pages/pages.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { ToastrModule } from 'ngx-toastr';
import { TradespersonModule } from './main/tradesperson/tradesperson.module';
import { TranslateModule } from '@ngx-translate/core';
import { fuseConfig } from 'app/fuse-config';
import { mainModule } from 'process';
import { AuthInterceptor } from './auth/authconfig.interceptor';
import { LoaderInterceptor } from './auth/loader.interceptor';
import { MailModule } from '../app/main/mail/mail.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from 'app/main/utils/fake-db.service';
const appRoutes: Routes = [
    {
        path      : '**', component: LoginComponent
    },
    {
        path       : '', loadChildren: () => import('../app/main/tradesperson/tradesperson.module').then(m => m.TradespersonModule)
    },
    {
        path       : '', loadChildren: () => import('../app/main/administrative/administrative.module').then(m => m.AdministrativeModule)
    },
    {
        path       : '', loadChildren: () => import('../app/main/mail/mail.module').then(m => m.MailModule)
    }
];

export function tokenGetter(): any {
    return localStorage.getItem('jwt-admin');
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        // InMemoryWebApiModule.forRoot(FakeDbService, {
        //     delay             : 0,
        //     passThruUnknownUrl: true
        // }),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        FlexLayoutModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        PagesModule,
        TradespersonModule,
        AdministrativeModule,
        MailModule,
        AppsModule,
        ToastrModule.forRoot({
                closeButton: true,
                autoDismiss: true,
                positionClass: 'toast-top-center',
         }),
        JwtModule.forRoot({
            config: {
              tokenGetter: tokenGetter,
              whitelistedDomains: [
                'localhost:3000',
                'localhost:8003',
                'localhost:8004',
                'localhost:8005',
                'localhost:8006',
                'localhost:8000',
                'tradespersonapi.azurewebsites.net',
                'drawhomeapi.azurewebsites.net',
                'brploginapi.azurewebsites.net',
                'brpapigateway.azurewebsites.net',
                'brpsecurity.azurewebsites.net'
              ],
              blacklistedRoutes: []
            }
          })
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
    providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        { 
          provide: HTTP_INTERCEPTORS, 
          useClass: LoaderInterceptor,
           multi: true 
        }
      ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
