import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';

import { MyProfileComponent } from './my-profile/my-profile.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { AuthguardGuard } from 'app/auth/authguard.guard';
import { MatProgressBarModule } from '@angular/material';

const appRoutes: Routes = [
    { path      : 'my-profile', component: MyProfileComponent,canActivate: [AuthguardGuard] },
    { path      : 'update-profile/:id', component: MyProfileComponent,canActivate: [AuthguardGuard] },
];

@NgModule({
    declarations: [
        MyProfileComponent
    ],
    imports: [
        MatProgressBarModule,
        RouterModule.forChild(appRoutes),
        BrowserModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,

        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatButtonModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class ProfileModule
{
}
