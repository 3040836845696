import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialListComponent } from './material-list/material-list.component';
import { MaterialCreateComponent } from './material-create/material-create.component';




const appRoutes: Routes = [
    { path      : 'add-new-material', component: MaterialCreateComponent },
    { path      : 'update-material/:id', component: MaterialCreateComponent },
    { path      : 'material-list', component: MaterialListComponent }
];

@NgModule({
    declarations: [
        MaterialListComponent
    ],
    imports     : [
        RouterModule.forChild(appRoutes),
        BrowserModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class MaterialModule
{
}
