import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { MailService } from 'app/main/mail/mail.service';
import { MailComposeDialogComponent } from 'app/main/mail/dialogs/compose/compose.component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector     : 'mail-main-sidebar',
    templateUrl  : './main-sidebar.component.html',
    styleUrls    : ['./main-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MailMainSidebarComponent implements OnInit, OnDestroy
{
    folders: any[];
    filters: any[];
    labels: any[];
    accounts: object;
    selectedAccount: string;
    dialogRef: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {MailService} _mailService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _mailService: MailService,
        public _matDialog: MatDialog,
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.accounts = {
            creapond    : 'johndoe@creapond.com',
            withinpixels: 'johndoe@withinpixels.com'
        };
        this.selectedAccount = 'creapond';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
         //this.folders = this._httpClient.get("assets/fake-db.service.json").subscribe(data => console.log(data));
           
         this._mailService.getJsonData()
         .then( result => {
           this.folders = result;
         })
         .catch( error => {
           console.log('Error Getting Data: ', error);
         });
        

            
        //this.getFolders(),

        //this._mailService.getFolders();

        // this._mailService.onFoldersChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(folders => {

        //         console.log(folders);
        //         this.folders = folders;
        //     });

        // this._mailService.onFiltersChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(filters => {
        //         this.filters = filters;
        //     });

        // this._mailService.onLabelsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(labels => {
        //         this.labels = labels;
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Compose dialog
     */
    composeDialog(): void
    {
        this.dialogRef = this._matDialog.open(MailComposeDialogComponent, {
            panelClass: 'mail-compose-dialog'
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if ( !response )
                {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                switch ( actionType )
                {
                    /**
                     * Send
                     */
                    case 'send':
                        console.log('new Mail', formData.getRawValue());
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':
                        console.log('delete Mail');
                        break;
                }
            });
    }
}
