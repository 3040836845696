import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemCategoryListComponent } from './item-category-list/item-category-list.component';
import { ItemCategoryCreateComponent } from './item-category-create/item-category-create.component';
import { AuthguardGuard } from 'app/auth/authguard.guard';




const appRoutes: Routes = [
    { path      : 'add-item-category', component: ItemCategoryCreateComponent, canActivate: [AuthguardGuard] },
    { path      : 'update-item-category/:id', component: ItemCategoryCreateComponent, canActivate: [AuthguardGuard] },
    { path      : 'item-category-list', component: ItemCategoryListComponent, canActivate: [AuthguardGuard] }
];

@NgModule({
    declarations: [
    ItemCategoryListComponent,
    ItemCategoryCreateComponent],
    imports     : [
        RouterModule.forChild(appRoutes),
        BrowserModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class ItemCategoryModule
{
}
