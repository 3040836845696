import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatDatepickerModule, MatNativeDateModule, MatTableModule, MatProgressBarModule } from '@angular/material';
import { RouterModule, Routes } from '@angular/router';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { BillingComponent } from './service-registration/billing/billing.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DescriptionComponent } from './service-registration/description/description.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GeneralComponent } from './service-registration/general/general.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JobCategoriesComponent } from './service-registration/job-categories/job-categories.component';
import { LocationComponent } from './service-registration/location/location.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { ServiceRegistrationComponent } from './service-registration/service-registration.component';
import { ToastrModule } from 'ngx-toastr';
import { VettingStatusComponent } from './service-registration/vetting-status/vetting-status.component';
import { ClaimedServiceListComponent } from './claimed-services/claimed-service-list/claimed-service-list.component';
import { ClaimedServiceRegistrationComponent } from './claimed-services/claimed-service-registration/claimed-service-registration.component';
import { AuthInterceptor } from 'app/auth/authconfig.interceptor';
import { AddNewServicesComponent } from './service-registration/add-new-services/add-new-services.component';
import { AuthguardGuard } from 'app/auth/authguard.guard';

const appRoutes: Routes = [
    { path      : 'service-registration', component: ServiceRegistrationComponent ,canActivate: [AuthguardGuard]},
    { path      : 'claimed-service', component: ClaimedServiceListComponent ,canActivate: [AuthguardGuard]},
    { path      : 'claimed-service-registration/:id', component: ClaimedServiceRegistrationComponent ,canActivate: [AuthguardGuard]},
    { path      : 'add-new-services', component: AddNewServicesComponent ,canActivate: [AuthguardGuard]}
];

@NgModule({
    declarations: [
        ServiceRegistrationComponent,
        GeneralComponent,
        DescriptionComponent,
        JobCategoriesComponent,
        LocationComponent,
        VettingStatusComponent,
        BillingComponent,
        ClaimedServiceListComponent,
        ClaimedServiceRegistrationComponent,
        AddNewServicesComponent,
    ],
    imports     : [
        MatProgressBarModule,
        MatTableModule,
        RouterModule.forChild(appRoutes),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        AngularEditorModule,
        MatDividerModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatSelectModule,
        MatGridListModule,
        MatCheckboxModule,
        MatRadioModule,
        FormsModule,
        MatAutocompleteModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        CommonModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    exports     : [
        MatTableModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FlexLayoutModule,
        AngularEditorModule,
        MatDividerModule,
        MatTabsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatExpansionModule,
        MatSelectModule,
        MatGridListModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ],
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        }
      ]
})
export class ServicesModule
{
}
