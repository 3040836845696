import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, merge } from 'rxjs';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UserService } from 'app/main/administrative/user/user.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;
    menu = [];
    subMenu = [];
    leftnavigation;
    ruleId
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService
    )
    {
        this._unsubscribeAll = new Subject();
    }
    nave: [];
    ngOnInit(): void
    {
         if(JSON.parse(localStorage.getItem('user')) != null){
            this.ruleId = JSON.parse(localStorage.getItem('user')).userRuleId;
        
            let subMenu = []
            this.menu = JSON.parse(localStorage.getItem('user')).leftnavigation;
            
            this._fuseNavigationService.onNavigationChanged
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => {
                this.navigation = JSON.parse(localStorage.getItem('user')).leftnavigation;
                this.navigation = this.navigation.filter(
                    function(e) {
                    // e.children = e.children.filter(
                    //     function(g){
                    //         return this.indexOf(g.title)== -1;
                    //     },
                    //     subMenu
                    // )
                    return this.indexOf(e.translate) == -1;
                    },
                    this.menu
                );
            });
        }
    }
}
