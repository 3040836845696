import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { RatingListComponent } from './rating-list/rating-list.component';
import { RatingDetailsComponent } from './rating-details/rating-details.component';
import { RatingListApprovedComponent } from './rating-list-approved/rating-list-approved.component';
import { RatingListRejectComponent } from './rating-list-reject/rating-list-reject.component';
// import { LoginGuard } from 'app/auth/auth.service';

const appRoutes: Routes = [
    { path      : 'approved-review', component: RatingListApprovedComponent},
    { path      : 'rejected-review', component: RatingListRejectComponent},
    { path      : 'rating-management', component: RatingListComponent},
    { path      : 'rating-details/:id', component: RatingDetailsComponent }
];


@NgModule({
    declarations: [
        RatingListComponent,
        RatingDetailsComponent,
        RatingListApprovedComponent,
        RatingListRejectComponent
],
    imports     : [
        RouterModule.forChild(appRoutes),
        BrowserModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatTableModule,
        MatButtonModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class RatingModule
{
}
