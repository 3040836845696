import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-vetting-list',
  templateUrl: './vetting-list.component.html',
  styleUrls: ['./vetting-list.component.scss']
})
export class VettingListComponent implements OnInit {
  waitingList: string;
  approvedList: string;

  waitingListColumns: string[] = ['position', 'companyName', 'category', 'identityName', 'id'];
  approvedListColumns: string[] = ['position', 'companyName', 'category', 'identityName'];

  constructor(
      private vettingService: VettingService,
      private router: Router
  ) {}

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.vettingService.getServices().subscribe(x => {
      this.waitingList = x.filter(a => a.isChecked === true && a.isApproved === false);
      this.approvedList = x.filter(a => a.isChecked === true && a.isApproved === true);
    });
  }
  
  checkDetail(id: number): void {
    this.router.navigate(['update-vetting', id]).then();
  }

}

