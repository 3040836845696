import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AccountService } from 'app/main/pages/authentication/login/account.service';
import { Router } from '@angular/router';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AccountService,
    private router: Router
    ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken(); 
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });
    return next.handle(req).pipe(
      tap(
        event => {},
        error => {
          if (error.status === 401) {
            this.router.navigate(['/']);
          }
        }
      )
    );
  }
  
}