import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobListComponent } from './job-list/job-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { ProfileModule } from './profile/profile.module';
import { ServicesModule } from './services/services.module';
import { ToastrModule } from 'ngx-toastr';
import { AuthguardGuard } from 'app/auth/authguard.guard';
import { MatProgressBarModule } from '@angular/material';

const appRoutes: Routes = [
    { path      : 'job-list', component: JobListComponent , canActivate: [AuthguardGuard]},
    { path      : 'job-details/:id', component: JobDetailsComponent , canActivate: [AuthguardGuard]}
];

@NgModule({
    declarations: [JobListComponent, JobDetailsComponent],
    imports     : [
        MatProgressBarModule,
        RouterModule.forChild(appRoutes),
        ServicesModule,
        ProfileModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        MatTableModule,
        MatButtonModule
    ],
    exports     :[
        ServicesModule,
        ProfileModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class TradespersonModule
{
}
