import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  userId;
  id;
  buttonText;
  profileImageFile      : File = null;
  profileImageUrl       : string;
  form                  : FormGroup;
  profilePhotoUrl       : string;
  profilePhoto          : File = null;
  isImageLoading        : boolean = false;
  isLoading: boolean = false;
  isDataLoading        : boolean = false;
  
  constructor(
    private profileService: ProfileService,
    private formBuilder       : FormBuilder,
    private toastr            : ToastrService) { }
  
  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('user')).id != null){
      this.userId = JSON.parse(localStorage.getItem('user')).id;
    }
    this.loadProfile();
    this.defaultImage();
    this.form = this.careateProfileForm();
  }

  careateProfileForm(): FormGroup{
    return this.formBuilder.group({
      profilePhoto		: [''],
      firstName       : ['', [Validators.required]],
      lastName        : ['', [Validators.required]],
      address         : [''],
      email           : [''],
      cellNo          : [''],
      userId          : [''],

      title           : [''],
      address2        : [''],
      postalTown      : [''],
      postalCode      : [''],

    });
  }
  
  onCompanyLogoFile(fileInput: any) {
    this.profilePhoto = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.profilePhotoUrl = reader.result as string;
        this.isImageLoading = false;
      };
    }
  }

  onProfileImageFile(fileInput: any): void {
      this.profileImageFile = fileInput.target.files[0] as File;

      const reader = new FileReader();
      if (fileInput.target.files && fileInput.target.files.length) {
          const [file] = fileInput.target.files;
          reader.readAsDataURL(file);

          reader.onload = () => {
              this.profileImageUrl = reader.result as string;
          };
      }
  }

  loadProfile(): void{
    this.isLoading = true;
    this.isDataLoading = true;
    this.profileService.getMyProfileById(this.userId)
      .subscribe(data => {

        if (data === null){
          this.buttonText        = 'Save';
        }
        else{
          this.buttonText        = 'Update'; 

          if(data.profilePhoto !== null)
          {

            this.profilePhotoUrl = data.profilePicture;
            data.companyLogo = '';
          }
          for (const key in data) {
            if(data[key] == 'null'){
             data[key] = '';
            }
          }
          this.form.patchValue(data);
          this.isLoading = false;
          this.isDataLoading = false;
        }
      }, error => console.log(error));
  }
  
  onSubmit(data): any {
    this.isLoading = true;
    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).id;
    }
    else{
      alert('Error');
      return;
    }
    
    const formData = new FormData();
    formData.append('address', this.form.value.address === 'null' ? '': this.form.value.address);
    formData.append('address2', this.form.value.address2 === 'null' ? '': this.form.value.address2);
    formData.append('cellNo', this.form.value.cellNo === 'null' ? '': this.form.value.cellNo);
    formData.append('profilePhoto', this.profilePhoto);
    formData.append('email', this.form.value.email === 'null' ? '': this.form.value.email);
    formData.append('firstName', this.form.value.firstName === 'null' ? '': this.form.value.firstName);
    formData.append('lastName', this.form.value.lastName === 'null' ? '': this.form.value.lastName);
    formData.append('postalCode', this.form.value.postalCode === 'null' ? '': this.form.value.postalCode);
    formData.append('postalTown', this.form.value.postalTown === 'null' ? '': this.form.value.postalTown);
    formData.append('title', this.form.value.title);
    
    
    
    if (this.buttonText === 'Save'){
      formData.append('mapperId', this.userId);
      this.profileService.savemyProfile(formData)
      .subscribe((response) => {
          if(response === 200){
            this.toastr.success('Information saved successfully', 'BRP');
          }
        this.loadProfile();
        },
        (error) => {
          this.isLoading = false;
          alert(error);
          console.error(error);
        }
      );
    }else{
      formData.append('userId', this.form.value.userId);
      this.profileService.updateProfile(formData)
      .subscribe((response) => {
          if(response === 200){
            this.toastr.success('Information updated successfully', 'BRP');
          }
        },
        (error) => {
          this.isLoading = false;
          alert(error);
          console.error(error);
        }
      );
    }
    this.isLoading = false;
    this.defaultImage();
  }

  defaultImage(): void{
    this.profileImageUrl = '../../../../../assets/images/default-placeholder.png';
  }
}
