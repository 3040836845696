import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
  ratingListColumns: string[] = ['position', 'companyName', 'addressLine1', 'identityName', 'cellNo', 'companyWebsite', 'tradeServiceId'];
  isLoading: boolean = false;
  constructor(
      private router: Router,
      private toastr            : ToastrService,
      private vettingService: VettingService,
  ) {}
  notificationList: any;
  wishlist: any;
  ngOnInit(): void {
      
      this.getNotification();
  }
  
  
  
  getNotification(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.isLoading = true;
    this.vettingService.getWishlist(userId).subscribe(responce => {
      this.isLoading = false;
      this.wishlist = responce;
    });
  }

  checkDetail(notificationId){
    this.router.navigate(['/notification-details', notificationId]);
  }
}

