import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading              : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isServiceWeCoverLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSercicesLoading      : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {
  }
}
