import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';

import { TradeService } from 'app/main/model/tradeService';
import { ServicesService } from '../../services.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'app-vetting-status',
  templateUrl: './vetting-status.component.html',
  styleUrls: ['./vetting-status.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class VettingStatusComponent implements OnInit {
  vettingsDoc;
  accreditationDoc
  accreditationContainer                = [];
  userId                                : any;
  form                                  : FormGroup;
  tradeService                          : any;
  identityImageUrl                      : string;
  companyRegisterCertificateImageUrl    : string;
  vatCertificateImageUrl                : string;
  insuranceCertificateImageUrl          : string;
  identityFile                          : File = null;
  companyRegisterCertificateFile        : File = null;
  vatCertificateFile                    : File = null;
  insuranceCertificateFile              : File = null;
  accreditation1ImageUrl                : string;
  accreditation2ImageUrl                : string;
  accreditation3ImageUrl                : string;
  accreditation1File                    : File = null;
  accreditation2File                    : File = null;
  accreditation3File                    : File = null;
  submitted                             = false;
  disabled                              = false;
  expiryDate

  constructor(private servicesService: ServicesService,
    private formBuilder: FormBuilder,
    private toastr            : ToastrService,
    private dateAdapter: DateAdapter<Date>) { 
          this.tradeService = new TradeService();
          // this.dateAdapter.setLocale('your locale'          ); 
  }
    
  get f() { return this.form.controls; }
  ngOnInit() {
    this.getVettingFile();
    this.getTradeService();
    
    this.form = new FormGroup({
      identityId                  : new FormControl(null),
      companyRegisterCertificate  : new FormControl(null),
      vatCertificate              : new FormControl(null),
      insuranceCertificate        : new FormControl(null),
      identityName                : new FormControl('', [Validators.required, Validators.minLength(3)]),
      tradingAddress              : new FormControl(''),
      insuredAmount               : new FormControl('', [Validators.required]),
      expiryDate                  : new FormControl('', [Validators.required]),

      accreditation1              : new FormControl(null),
      accreditation2              : new FormControl(null),
      accreditation3              : new FormControl(null),

      courseName1                 : new FormControl(''),
      instituteName1              : new FormControl(''),
      yearsAcquired1              : new FormControl(''),

      courseName2                 : new FormControl(''),
      instituteName2              : new FormControl(''),
      yearsAcquired2              : new FormControl(''),

      courseName3                 : new FormControl(''),
      instituteName3              : new FormControl(''),
      yearsAcquired3              : new FormControl(''),
      
    });
  }
  
  getTradeService(){
    if (localStorage.getItem('user') !== null) {
        this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }

    this.servicesService.getTradeServices(this.userId)
        .subscribe((response) => {
          if(response.isChecked === true){
            this.form.disable();
            this.disabled = true;
          }

        this.tradeService = response;
       
        this.form.patchValue(this.tradeService);
        this.getAccreditationFiles(this.tradeService.tradeServiceId);
    },
  );
  }

  getVettingFile(){
    if (localStorage.getItem('user') !== null) {
        this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    
    this.servicesService.getVettingFileByUserId(this.userId)
        .subscribe((response) => {
          this.vettingsDoc = response;
          
          this.vettingsDoc.forEach(x=> {
            if(x.vettingHead === 'IdentityFile')
            {
             this.identityImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'CompanyRegisterCertificateFile')
            {
              this.companyRegisterCertificateImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'VATCertificateFile')
            {
              this.vatCertificateImageUrl = x.vettingValueofHead
            }
            else if(x.vettingHead === 'InsuranceCertificateFile')
            {
              this.insuranceCertificateImageUrl = x.vettingValueofHead
            }
          }); 
      },
    );
  }

  getAccreditationFiles(tradeServiceId){
    if (localStorage.getItem('user') !== null) {
        this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    
    // this.form.patchValue({
    //   courseName2                 : new FormControl(''),
    //   instituteName2              : new FormControl(''),
    //   yearsAcquired2              : new FormControl(''),
    // });

    this.servicesService.getAccreditation(tradeServiceId)
        .subscribe((response) => {
          this.accreditationDoc = response;
          
          this.accreditationDoc.forEach(x=> {
            if(x.fileName === 'accreditation1File')
            {
             this.accreditation1ImageUrl = x.resourceFile
             
             this.form.patchValue({
              courseName1                 : x.courseName,
              instituteName1              : x.instituteName,
              yearsAcquired1              : x.yearsAcquired,
             });
            }
            else if(x.fileName === 'accreditation2File')
            {
              this.accreditation2ImageUrl = x.resourceFile
              
              this.form.patchValue({
                courseName2                 : x.courseName,
                instituteName2              : x.instituteName,
                yearsAcquired2              : x.yearsAcquired,
               });
            }
            else if(x.fileName === 'accreditation3File')
            {
              this.accreditation3ImageUrl = x.resourceFile
              
              this.form.patchValue({
                courseName3                 : x.courseName,
                instituteName3              : x.instituteName,
                yearsAcquired3              : x.yearsAcquired,
               });
            }
          });
          
      },
    );
  }
  
  onAccreditation1File(fileInput: any) {
    this.accreditation1File = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.accreditation1ImageUrl = reader.result as string;
      };
    }
  }

  onAccreditation2File(fileInput: any) {
    this.accreditation2File = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.accreditation2ImageUrl = reader.result as string;
      };
    }
  }

  onAccreditation3File(fileInput: any) {
    this.accreditation3File = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.accreditation3ImageUrl = reader.result as string;
      };
    }
  }

  saveVettingStatus(tabName: string) {
    this.expiryDate = new Date (this.form.value.expiryDate).toISOString();
    
    this.submitted = true;

    if(this.form.value.identityName === ''){
      this.toastr.success('Please input your full name.', 'BRP');
      return
    }
    if(this.form.value.insuredAmount === ''){
      this.toastr.success('Please input insured amount.', 'BRP');
      return
    }
    if(this.form.value.expiryDate === ''){
      this.toastr.success('Please input expiry date.', 'BRP');
      return
    }

    if (this.form.invalid) {
        return;
    }

    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    
    const formData = new FormData();
    formData.append('userId', this.userId);
    formData.append('deleted', JSON.stringify(this.accreditationContainer));

    formData.append('identityName', this.form.value.identityName);
    formData.append('tradingAddress', this.form.value.tradingAddress);
    formData.append('insuredAmount', this.form.value.insuredAmount);
    formData.append('expiryDate', this.expiryDate);

    formData.append('IdentityFile', this.identityFile);
    formData.append('CompanyRegisterCertificateFile', this.companyRegisterCertificateFile);
    formData.append('VATCertificateFile', this.vatCertificateFile);
    formData.append('InsuranceCertificateFile', this.insuranceCertificateFile);
    
    formData.append('courseName1', this.form.value.courseName1);
    formData.append('instituteName1', this.form.value.instituteName1);
    formData.append('yearsAcquired1', this.form.value.yearsAcquired1);
    formData.append('accreditation1File', this.accreditation1File);
    
    formData.append('courseName2', this.form.value.courseName2);
    formData.append('instituteName2', this.form.value.instituteName2);
    formData.append('yearsAcquired2', this.form.value.yearsAcquired2);
    formData.append('accreditation2File', this.accreditation2File);
    
    formData.append('courseName3', this.form.value.courseName3);
    formData.append('instituteName3', this.form.value.instituteName3);
    formData.append('yearsAcquired3', this.form.value.yearsAcquired3);
    formData.append('accreditation3File', this.accreditation3File);

    this.servicesService.saveVetting(formData)
      .subscribe((response) => {//Next callback
        if(response === 200){
          for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
            if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
              {
                  (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
              }
            }
          }
        },
        (error) => {
          alert(error);
        }
      );
  }

  addNewAccreditation2(imageUrl){
    this.accreditation2ImageUrl ='assets/images/default-placeholder.png';
  }

  addNewAccreditation3(imageUrl){
    this.accreditation3ImageUrl ='assets/images/default-placeholder.png';
  }
  
  deleteImageBox(image): void {
    
    switch (image) {
      case 'accreditation1ImageUrl':
        this.accreditation1ImageUrl ='assets/images/default-placeholder.png';
        this.accreditationContainer.push('accreditation1File');
          break;

      case 'accreditation2ImageUrl':
        this.accreditation2ImageUrl ='';
        this.accreditationContainer.push('accreditation2File');
          break;

      case 'accreditation3ImageUrl':
       this.accreditation3ImageUrl ='';
        this.accreditationContainer.push('accreditation3File');
          break;
          
      default:
          break;
    }
  }

  previousTab(tabName: string) {
    for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
       {
          (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
       }
     }
  }

  onIdentityFile(fileInput: any) {
    this.identityFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.identityImageUrl = reader.result as string;
      };
    }
  }

  onCompanyRegisterCertificateFile(fileInput: any) {
    this.companyRegisterCertificateFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.companyRegisterCertificateImageUrl = reader.result as string;
      };
    }
  }

  onVatCertificateFile(fileInput: any) {
    this.vatCertificateFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.vatCertificateImageUrl = reader.result as string;
      };
    }
  }

  onInsuranceCertificateFile(fileInput: any) {
    this.insuranceCertificateFile = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.insuranceCertificateImageUrl = reader.result as string;
      };
    }
  }
}
