import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getServices(): Observable<any> {
    return this.http.get(`${environment.baseUrl}` + '/api/Tradesman/GetTradeService');
  }

  getUserRules(): Observable<any> {
    return this.http.get(`${environment.baseUrl}` + '/User/GetUserRules');
  }

  getUserRuleId(ruleId): Observable<any> {
    return this.http.get(`${environment.baseUrl}` + '/User/GetUserRuleId/' + ruleId);
  }
}
