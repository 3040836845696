import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MaterialService } from '../material.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss']
})
export class MaterialListComponent implements OnInit {
  
  materialList: any;
  dataSource: MatTableDataSource<any>;

  constructor(private materialService: MaterialService,
              private router: Router) {
                this.dataSource = new MatTableDataSource();
              }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.materialService.getMaterials().subscribe(x => {
      this.materialList =  x;
    });
  }

  deleteMaterial(id: number): void {}

  materialDetails(id: number): void{
    this.router.navigate(['update-material', id]);
  }

}

