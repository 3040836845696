import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

// import { AuthService } from "app/auth/auth.service";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class UserService {
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    
    constructor(private http          : HttpClient,
                // private authService   : AuthService
                ) { }
  
    getMenuSubmenu(userId): Observable<any> {
      return this.http.get(environment.baseUrl + '/User/GetMenuSubmenu/' + userId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getUsers(): Observable<any> {
        return this.http.get(environment.baseUrl + '/User/GetUsers')
        .pipe(
          catchError(this.errorHandler)
        );
    }

    getUserById(userId): Observable<any> {
      // alert(userId);
      return this.http.get(environment.baseUrl + '/User/GetUserById/' + userId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getUserRules(): Observable<any> {
      return this.http.get(environment.baseUrl + '/User/GetUserRules')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    updateUserRules(userName, userRuleId): Observable<any> {
      return this.http.get(environment.baseUrl + '/User/UpdateUserRule/' + userName +'/'+ userRuleId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getUserRuleId(ruleId): Observable<any> {
    return this.http.get(environment.baseUrl + '/User/GetUserRuleId/' + ruleId)
    .pipe(
      catchError(this.errorHandler)
    );
    }

    getRuleTaskMapper(menuId): Observable<any> {
      return this.http.get(environment.baseUrl + '/User/GetRuleTaskMapper/' + menuId)
      .pipe(
        catchError(this.errorHandler)
      );
    }

    saveOrUpdateRuleTaskMapper(userRuleId, ruleTaskArray): Observable<any> {
     
      return this.http.post(environment.baseUrl +'/User/SaveOrUpdateRuleTaskMapper/'+ userRuleId, ruleTaskArray)
      .pipe(
        catchError(this.errorHandler)
      )
    }

    getUserTasks(): Observable<any> {
      return this.http.get(environment.baseUrl + '/User/GetUserTask')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getTaskById(taskId): Observable<any> {
    return this.http.get(environment.baseUrl + '/User/GetUserTaskById/' + taskId)
    .pipe(
      catchError(this.errorHandler)
    );
    }
    
    getUserRuleMapper(): Observable<any> {
      return this.http.get(environment.baseUrl + '/User/GetUserRuleMapper')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    getLeftNavigation(): Observable<any> {
      return this.http
      .get(environment.baseUrl + '/User/GetLeftNavigation')
      .pipe(
        catchError(this.errorHandler)
      );
    }

    update(user): Observable<any> {
      return this.http.put(environment.baseUrl +'/User/Update/', user)
      .pipe(
        catchError(this.errorHandler)
      ); 
    }
    // tslint:disable-next-line: ban-types
    createMaterial(employee: Object): Observable<Object> {
      return this.http.post(`${environment.baseUrl}`, employee);
    }
  
    // registerUser(user): Observable<any> {
    //   return this.http.post(`${environment.baseUrl}` +'/User/Register', user,
    //   { headers: this.authService.getHeaders() })
    //   .pipe(
    //     catchError(this.errorHandler)
    //   );   

    // }

    registerUser(user): Observable<any> {
      return this.http.post(`${environment.baseUrl}` +'/api/Auth/register', user)
      .pipe(
        catchError(this.errorHandler)
      );   

    }

    resetPassword(userId, newPassword): Observable<any> {
      
      let user = {email: userId, password: newPassword}
      return this.http
      .post(`${environment.baseUrl}` +'/api/Auth/ChangePassword', user, { headers: new HttpHeaders({"Content-Type": "application/json"}) })
      .pipe(
        catchError(this.errorHandler)
      );   

    }


    authenticateUser(user): Observable<any> {
      return this.http.post(`${environment.baseUrl}` +'/User/Authenticate', user)    
    }
    // tslint:disable-next-line: typedef
    errorHandler(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        // errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
        errorMessage = `${error.error.message}`;
      }
      return throwError(errorMessage);
   }
   
  }
  