import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

// import { AuthService } from 'app/auth/auth.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VettingService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(private http          : HttpClient,
              // private authService   : AuthService
              ) { }



  getClaimedService(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetclaimedserviceForVetting/');

  }

  getTradeServiceForNotification(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetTradeServiceForNotification/');

  }

  getNewTradeServiceForVetting(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetNewTradeServiceForVetting/');

  }
  getServices(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetTradeServiceForVetting');
  }

  getRating(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetTradeServiceReviewsForVerification');
  }

  getClaimedVettingManagementData(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetClaimedVettingManagementData/'+ tradeServiceId);
  }

  getNewTradeServiceManagementData(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetNewTradeServiceManagementData/'+ tradeServiceId);
  }
  getVettingManagementData(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/GetVettingManagementData/'+ tradeServiceId);
  }

  approvedTradeService(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/ApprovedTradeService/'+ tradeServiceId);
  }

  approvedTradeServiceClaimed(tradeServiceId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/ApprovedTradeServiceClaimed/'+ tradeServiceId+ '/' + userId);
  }
  
  approvedNewTradeService(tradeServiceId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/ApprovedNewTradeService/'+ tradeServiceId+ '/' + userId);
  }

  rejectTradeService(tradeServiceId, rejectReason,userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/api/Tradesman/RejectTradeService/'+ tradeServiceId + '/' + rejectReason + '/' + userId);
  }

  addNotification(notification): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveNotification/', notification);
  }

  getNotification(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetNotification/' + userId);
  }

  getWishlist(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetWishlist/' + userId);
  }

  getCommunicationToTradesperson(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetCommunicationToTradesperson/' + userId);
  }

  getAnnouncements(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetAnnouncement/');
  }

  saveAnnouncement(announcement): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/api/Tradesman/SaveAnnouncement/', announcement);
  }

  downloadFile(fileName): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/Administrative/DownloadFile?fileName=637483997844273629.png');
  }
  
  getVettingStatus(tradeServiceId): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/api/Tradesman/GetVettingStatus/' + tradeServiceId);
  }
  
  // tslint:disable-next-line: typedef  GetTradeService
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
}
