import { Component, OnInit } from '@angular/core';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  step: number;
  isLoading: boolean = false;
  announcementList: any;

  constructor(
    private vettingService: VettingService,
  ) { 
    this.step = -1;
  }

  ngOnInit(): void
  {
      this.getAnnouncements();
  }

  getAnnouncements(){
    let userId = JSON.parse(localStorage.getItem('user')).id;
    this.isLoading = true;
    this.vettingService.getAnnouncements().subscribe(responce => {
      this.isLoading = false;
      this.announcementList = responce;
    });
  }

  setStep(index: number): void
    {
        this.step = index;
    }
}
