export class TradeService {
    tradeServiceId: number;
    itemCategoryId:number;

    serviceDescription: string;

    cellNo: string;
    addressLine1: string;
    addressLine2: string;

    areasCovered: string;

    businessType: string;
    companyName: string;
    companyWebsite: string;
    country: string;
    facebookLink: string;
    numberofEmployees: string;

    operatingRadius: string;

    pinterestLink: string;
    postCode: string;
    postalTown: string;
    serviceSince: string;
    twitterLink: string;
    companyLogo: string;
    landlineTelephone: string;
    videoLink: string;

    expiryDate: string;
    identityName: string;
    insuredAmount: string;
    tradingAddress: string;
    userId: string;
    

    constructor(tradeService?) {
        tradeService = tradeService || {};
        this.tradeServiceId         = tradeService.tradeServiceId;
        this.itemCategoryId         = tradeService.itemCategoryId;
        this.serviceDescription     = tradeService.serviceDescription
        this.cellNo = tradeService.cellNo || '';
        this.addressLine1 = tradeService.addressLine1 || '';
        this.addressLine2 = tradeService.addressLine2 || '';
        this.areasCovered = tradeService.addressLine2 || '';

        this.businessType = tradeService.businessType || '';
        this.companyName = tradeService.companyName || '';
        this.companyWebsite = tradeService.companyWebsite || '';
        this.country = tradeService.country || '';
        this.facebookLink = tradeService.facebookLink || '';
        this.numberofEmployees = tradeService.numberofEmployees || '';

        this.operatingRadius = tradeService.operatingRadius || '';
        this.pinterestLink = tradeService.pinterestLink || '';
        this.postCode = tradeService.postCode || '';
        this.postalTown = tradeService.postalTown || '';
        this.serviceSince = tradeService.serviceSince || '';
        this.twitterLink = tradeService.twitterLink || '';
        this.companyLogo = tradeService.companyLogo || '';
        this.landlineTelephone = tradeService.landlineTelephone || '';
        this.videoLink = tradeService.videoLink || '';

        this.expiryDate = tradeService.expiryDate || '';
        this.identityName = tradeService.identityName || '';
        this.insuredAmount = tradeService.insuredAmount || '';
        this.tradingAddress = tradeService.tradingAddress || '';

        this.userId = tradeService.userId || '';

    }
  }
  