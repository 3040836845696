import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemCategoryModel } from 'app/main/model/ItemCategoryModel';
import { ItemCategoryService } from '../item-category.service';


@Component({
  selector: 'app-item-category-create',
  templateUrl: './item-category-create.component.html',
  styleUrls: ['./item-category-create.component.scss']
})
export class ItemCategoryCreateComponent implements OnInit {

  id: number;
  form: FormGroup;
  itemCategoryModel: ItemCategoryModel;
  buttonText: string;
  itemCategoryList: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    private itemCategoryService: ItemCategoryService,) {
      this.itemCategoryModel = new ItemCategoryModel();
     }

  ngOnInit() {
    this.getAllItemCategory();
    this.id = this.route.snapshot.params['id'];
    this.form                      = this.createDrawHomeForm();

    if (this.id === undefined){
      this.buttonText        = 'Save';
      this.itemCategoryModel = new ItemCategoryModel();
    }else{
      this.buttonText        = 'Update';   
      this.itemCategoryService.getItemCategoryById(this.id)
      .subscribe(data => {
        this.form.patchValue(data);
      }, error => console.log(error));
    }
  }

  getAllItemCategory(): void{
    this.itemCategoryService.getAllItemCategory().subscribe(res => {
      this.itemCategoryList = res;
    });
  }
  
  createDrawHomeForm(): FormGroup{
    return this.fb.group({
      itemCategoryId              : [this.itemCategoryModel.itemCategoryId, Validators.required],
      itemCategoryName            : [this.itemCategoryModel.itemCategoryName, Validators.required],
      parentId                    : [this.itemCategoryModel.parentId, Validators.required]
    });
  }

  submit(): void{
    if(this.form.valid){
      if(this.buttonText === 'Save'){
        this.itemCategoryService.saveItemCategory(this.form.value).subscribe(
            (response) => {//Next callback
              if(response === 200){
                this.router.navigate(['/item-category-list']);
              }
            },
            (error) => { //Error callback
              alert(error);
              console.error(error);
            }
          );
        }
        else{
          this.itemCategoryService.updateItemCategory(this.form.value).subscribe(
            (response) => {
              if(response === 200){
                this.router.navigate(['/item-category-list']);
              }
            },
            (error) => {
              alert(error);
              console.error(error);
            }
          );
        }
    }
    else{
      alert('Please fill up the form.');
    }
  }
}
