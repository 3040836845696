import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { SampleComponent } from './sample.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthguardGuard } from 'app/auth/authguard.guard';

const routes = [
    {
        path     : 'home', component: SampleComponent, canActivate: [AuthguardGuard]
    }
];

@NgModule({
    declarations: [
        SampleComponent
    ],
    imports     : [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule
    ],
    exports     : [
        SampleComponent
    ]
})

export class SampleModule
{
}
