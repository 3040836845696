import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-rule-list',
  templateUrl: './user-rule-list.component.html',
  styleUrls: ['./user-rule-list.component.scss']
})
export class UserRuleListComponent implements OnInit {
  
  userRuls: any;
  dataSource: MatTableDataSource<any>;
  isLoading: boolean = false;
  userRuleListColumns: string[] = ['position', 'name', 'delete',  'edit'];
  constructor(private userService: UserService,
              private router: Router) {
                this.dataSource = new MatTableDataSource();
              }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.isLoading = true;
    this.userService.getUserRules().subscribe(x => {
      this.userRuls =  x;
      this.isLoading = false;
    });
  }

  deleteMaterial(id: number): void {}

  materialDetails(id: number): void{
    this.router.navigate(['update-user-rule', id]);
  }

  addNewUserRule(){
    this.router.navigate(["/add-new-user-rule"]);
  }
}
