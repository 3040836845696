import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { MaterialEntryComponent } from './material-entry/material-entry.component';
// import { MaterialListComponent1 } from './material-list/material-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialCreateComponent } from './material/material-create/material-create.component';
import { MaterialModule } from './material/material.module';
import { VettingModule } from './vetting/vetting.module';
import { RatingModule } from './rating/rating.module';
import { UserModule } from './user/user.module';
import { ItemCategoryModule } from './item-category/itemCategory.module';
// import { TradespersonScraperListComponent } from './tradesperson-scraper/tradesperson-scraper-list/tradesperson-scraper-list.component';
// import { TradespersonScraperDisplayComponent } from './tradesperson-scraper/tradesperson-scraper-display/tradesperson-scraper-display.component';
import { TradespersonScraperModule } from './tradesperson-scraper/tradesperson-scraper.module';



const appRoutes: Routes = [
    // { path      : 'add-new-item-category', component: MaterialEntryComponent },
    // { path      : 'update-item-category/:id', component: MaterialEntryComponent },
    // { path      : 'item-category-list', component: MaterialListComponent1 },
];

@NgModule({
    declarations: [
        // MaterialEntryComponent,
        // MaterialListComponent1,
        MaterialCreateComponent,
    ],
    imports     : [
        RouterModule.forChild(appRoutes),
        BrowserModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        VettingModule,
        RatingModule,
        UserModule,
        ItemCategoryModule,
        TradespersonScraperModule
    ],
    exports     :[
        MaterialModule,
        VettingModule,
        RatingModule,
        UserModule,
        ItemCategoryModule,
        TradespersonScraperModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
      ]
})
export class AdministrativeModule
{
}
