import { Component, OnInit } from '@angular/core';

import { RatingService } from '../rating.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rating-list',
  templateUrl: './rating-list.component.html',
  styleUrls: ['./rating-list.component.scss']
})
export class RatingListComponent implements OnInit {
    ratingList: string;
    isLoading: boolean = false;
    //'email',
    ratingListColumns: string[] = ['position', 'date',  'jobName', 'overallRating', 'tradeServiceReviewsId'];

    constructor(
        private router: Router,
        private ratingService: RatingService,
    ) {}

    ngOnInit(): void {
        this.reloadData();
    }

    reloadData(): void {
        this.isLoading = true;
        this.ratingService.getRatingList().subscribe(x => {
            this.ratingList = x;
            this.isLoading = false;
        });
    }

    reloadData2(): void {
        this.isLoading = true;
        this.ratingService.getRatingList2().subscribe(x => {
            this.ratingList = x;
            this.isLoading = false;
        });
    }

    checkDetail(id: number): void {
        this.router.navigate(['rating-details', id]).then();
    }

}

