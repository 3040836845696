import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserRuleTaskMapper } from 'app/main/model/userRuleTaskMapper';
import { UserService } from '../user.service';
import { isNull } from 'util';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-task-mapper',
  templateUrl: './user-task-mapper.component.html',
  styleUrls: ['./user-task-mapper.component.scss']
})
export class UserTaskMapperComponent implements OnInit {
  
  userTasks: any;
  userRule:any;
  dataSource: MatTableDataSource<any>;
  checked: boolean;
  userRuleTaskMapper: UserRuleTaskMapper
  selectedValue: string;
  form: FormGroup;
  // userRuleTaskMapper = {aa: '', bb: ''};
  // UserRuleTaskMapper
  isLoading: boolean = false;
  constructor(private userService: UserService,
              private router: Router) {
                this.dataSource = new MatTableDataSource();
                this.checked = false;
                this.userRuleTaskMapper = new UserRuleTaskMapper();
              }

  ngOnInit(): void {
   this.reloadData();
    this.getUserRule();
  }

  checkboxClicked(value){}

  onRuleChanged(event) {
    this.userService.getRuleTaskMapper(event).subscribe(x => {
      for (let menu of this.userTasks) {
        if(x.find(y => y.menuId === menu.menuId))
        {
          menu.isSelected = true;
        }
        else{
          menu.isSelected = false;
        }
      }
    });
  }



  // element = {userRuleTaskMapperId: 0, userRuleId: 0, menuId: 0};
  cart: any[];
  saveData(value1){
    let element = {userRuleTaskMapperId: 0, userRuleId: 0, menuId: 0}, cart = []
   
   this.userRuleTaskMapper = {
    menuId: 0,
    userRuleId: 0,
    userRuleTaskMapperId: 0
   }

    cart = this.userTasks.filter(x => x.isSelected == true);
    this.userService.saveOrUpdateRuleTaskMapper(value1, cart).subscribe(x => {
      this.userTasks =  x;
    });
  }
  reloadData(): void {
    this.isLoading = true;
    this.userService.getUserTasks().subscribe(x => {
      this.userTasks =  x;
      this.isLoading = false;
    });
  }

  getUserRule(): void {
    this.userService.getUserRules().subscribe(x => {
      this.userRule =  x;
    });
  }

  deleteMaterial(id: number): void {
  }

  materialDetails(id: number): void{
    this.router.navigate(['update-user-task', id]);
  }

}
