import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-vetting-list-forsupervisor',
  templateUrl: './vetting-list-forsupervisor.component.html',
  styleUrls: ['./vetting-list-forsupervisor.component.scss']
})
export class VettingListForsupervisorComponent implements OnInit {
  
  serviceList: any;
  dataSource: MatTableDataSource<any>;
  id: any;
  goOnline: boolean;
  tradeService;

  constructor(private vettingService: VettingService,
              private router: Router,
              private route: ActivatedRoute,) {
                this.dataSource = new MatTableDataSource();
              }

  ngOnInit(): void {
    if(this.route.snapshot.routeConfig.path === 'check-vetting/approved'){
      this.reloadApprovedData();
    }else{
      this.reloadUnapprovedData();
    }
  }
  
  getVettingManagementData(tradeServiceId){
    this.vettingService.getVettingManagementData(tradeServiceId)
        .subscribe((response) => {
            this.tradeService = response;
        });
  }
  
  reloadUnapprovedData(): void {
    this.vettingService.getServices().subscribe(x => {
      this.goOnline = true;
      this.serviceList =  x.filter(f=>f.isApproved == false);
    });
  }

  reloadApprovedData(): void {
    this.vettingService.getServices().subscribe(x => {
      this.goOnline = false;
      this.serviceList =  x.filter(f=>f.isApproved == true);
    });
  }

  deleteMaterial(id: number): void {}

  vettingCheck(id: number): void{
    this.router.navigate(['update-vetting', id]);
  }

}

