import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";

@Injectable({
    providedIn: "root",
})
export class ItemCategoryService {
    private headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'};
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    constructor(private http: HttpClient) { }
  
    saveItemCategory(itemCategory: Object): Observable<Object> {
        return this.http.post(environment.baseUrl + '/Administrative/InsertItemSubCategory', itemCategory, {'headers':this.headers})
        .pipe(
          catchError(this.errorHandler)
        );
      }

    updateItemCategory(itemCategory: Object): Observable<Object> {
        const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'} 
        return this.http.post(environment.baseUrl + '/Administrative/UpdateItemSubCategory', itemCategory, {'headers':headers})
        .pipe(
          catchError(this.errorHandler)
        );
    }

    getAllItemCategory(): Observable<any> {
       
      return this.http.get(environment.baseUrl + '/Itemcategory/GetAllCategory');
    }

    getCategoryList(): Observable<any> {
      return this.http.get(environment.baseUrl + '/Itemcategory/GetSubCategory');
    }

    getItemCategoryById(itemCategoryId): Observable<any> {
      return this.http.get(environment.baseUrl + '/Itemcategory/GetSubCategory/' + itemCategoryId)
      .pipe(
        catchError(this.errorHandler)
      );
    }
    
    errorHandler(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        // errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
        errorMessage = `${error.error.message}`;
      }
      return throwError(errorMessage);
   }
   
  }
  