import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VettingService } from '../vetting.service';
import { LoaderService } from 'app/auth/loader.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-announcement-create',
  templateUrl: './announcement-create.component.html',
  styleUrls: ['./announcement-create.component.scss']
})
export class AnnouncementCreateComponent implements OnInit {

  form: FormGroup;
  isLoading: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
        {
            name: 'quote',
            class: 'quote',
        },
        {
            name: 'redText',
            class: 'redText'
        },
        {
            name: 'titleText',
            class: 'titleText',
            tag: 'h1',
        },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        ['bold', 'italic'],
        ['fontSize']
    ]
  };
  
  constructor(
    private formBuilder                   : FormBuilder,
    private vettingService                : VettingService,
    public loaderService                  : LoaderService
    ) 
    {
      
    }

  ngOnInit() {
    this.createVettingRejectForm();
  }

  createVettingRejectForm(){
    this.form = this.formBuilder.group({
      header		           : [],
      detail		           : [],
    });
  }

  saveAnnouncement(){
    if(this.form.valid){
      this.isLoading = true;
      this.vettingService.saveAnnouncement(this.form.value)
          .subscribe((response) => {
              this.isLoading = false;
            },
            (error) => { //Error callback
              alert(error);
            }
          );
    }
  }
}
