import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TradeService } from 'app/main/model/tradeService';
import { ToastrService } from 'ngx-toastr';
import { DocumentViewerComponent } from '../document-viewer/document-viewer.component';
import { VettingService } from '../vetting.service';

@Component({
  selector: 'app-vetting-management-claimed',
  templateUrl: './vetting-management-claimed.component.html',
  styleUrls: ['./vetting-management-claimed.component.scss']
})
export class VettingManagementClaimedComponent implements OnInit {
  tradeServiceCostList = [{
      itemCategoryId: 0,
      itemCategory: {
          itemCategoryName: ''
      },
      serviceCost: '',
      tradeServiceCostId: 0,
      tradeServiceId: 0
  }]
  vettingList = [{
      vettingHead: '',
      vettingValueofHead: '',
  }];

  ourServices = [{
                  allWorkGuaranteed: false,
                  callOut: false,
                  cardsAccepted: false,
                  commercialWorkCovered: false,
                  domesticWorkCovered: false,
                  familyBusiness: false,
                  freeQuotes: false,
                  insuranceWork: false,
                  longEstablishedCompany: false,
                  nhsDiscounts: false,
                  oapDiscount: false,
                  ourServicesId: 0,
                  overExperience: false,
                  overTrading: false,
                  tradeService: null,
                  tradeServiceId: 0,
                  womenledBuisness: false
      }]


  portfolioImage1                     : string;
  portfolioImage2                     : string;
  portfolioImage3                     : string;
  portfolioImage4                     : string;
  portfolioImage5                     : string;
  portfolioImage6                     : string;
  portfolioImage7                     : string;
  portfolioImage8                     : string;
  portfolioImage9                     : string;
  portfolioImage10                    : string;
  
  companyLogoFile                     : string;
  identityFile                        : string;
  companyRegisterCertificateFile      : string;
  vATCertificateFile                  : string;
  insuranceCertificateFile            : string;
  primaryCategory  
  tradeService;                   

  goOnlingDisable: boolean;

  isGoOnlineLoading: boolean;
constructor(private vettingService: VettingService,
  private _matDialog: MatDialog,
  private router: Router,
  private route: ActivatedRoute,
  private toastr            : ToastrService) {
  this.tradeService = new TradeService();
  this.goOnlingDisable = true;
 }

 id;
 userId
ngOnInit(): void {
  this.id = this.route.snapshot.params['id'];
  this.userId = this.route.snapshot.params['userid'];
  this.getVettingManagementData(this.id)
  this.isGoOnlineLoading = false;
}

seeCustomerView()
{
    
}
approved() {
    this.isGoOnlineLoading = true;
    this.vettingService.approvedTradeServiceClaimed(this.id, this.userId)
        .subscribe((response) => {
            this.router.navigate(['claimed-tradesperson']);
            this.toastr.success('Tradesperson approved successfully!!', 'BRP');
            this.isGoOnlineLoading = false;
        },
            (error) => { //Error callback
                this.isGoOnlineLoading = false;
                alert(error);
            }
        );
}

reject(){
    this.router.navigate(['vetting-reject', this.id, this.userId]).then();
}

pass(){
  this.goOnlingDisable = false;
}

displayIdentityFile(): void
{
   let  vattingId = this.identityFile;
    this._matDialog.open(DocumentViewerComponent, {
        panelClass: 'knowledgebase-article-dialog',
        data      : {vattingId}
    });
}

displayCompanyRegisterCertificateFile(): void
{
   let  vattingId = this.companyRegisterCertificateFile;
    this._matDialog.open(DocumentViewerComponent, {
        panelClass: 'knowledgebase-article-dialog',
        data      : {vattingId}
    });
}

displayVatCertificateFile(): void
{
   let  vattingId = this.vATCertificateFile;
    this._matDialog.open(DocumentViewerComponent, {
        panelClass: 'knowledgebase-article-dialog',
        data      : {vattingId}
    });
}

getVettingManagementData(tradeServiceId){
  this.vettingService.getClaimedVettingManagementData(tradeServiceId)
      .subscribe((response) => {
          this.tradeService = response;
          this.ourServices = this.tradeService.ourServices;
          this.primaryCategory = this.tradeService.itemCategory.itemCategoryName;


          this.tradeServiceCostList = this.tradeService.tradeServiceCostList;
          this.vettingList = this.tradeService.vettingList;

          this.vettingList.forEach(resource => {
            if(resource.vettingHead ==='CompanyLogoFile')
              {
                  this.companyLogoFile = resource.vettingValueofHead;
              }
              if(resource.vettingHead ==='IdentityFile')
              {
                  this.identityFile = resource.vettingValueofHead;
              }
              else if(resource.vettingHead ==='CompanyRegisterCertificateFile')
              {
                  this.companyRegisterCertificateFile = resource.vettingValueofHead;
              }
              else if(resource.vettingHead ==='VATCertificateFile')
              {
                  this.vATCertificateFile = resource.vettingValueofHead;
              };
          });
      },
  );
}

  moveToSelectedTab(tabName: string): void {
      for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
          if ((document.querySelectorAll('.mat-tab-label-content')[i] as HTMLElement).innerText === tabName)
          {
              (document.querySelectorAll('.mat-tab-label')[i] as HTMLElement).click();
          }
      }
  }
}
