import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { UserRuleModel } from 'app/main/model/userRule';
import { UserService } from '../user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

// import { UserModel } from 'app/main/model/userModel';



@Component({
  selector: 'app-user-rule-mapper',
  templateUrl: './user-rule-mapper.component.html',
  styleUrls: ['./user-rule-mapper.component.scss']
})
export class UserRuleMapperComponent implements OnInit {
  
  // materialList: any;
  userRules: UserRuleModel[] = [];
  userName: string;
  form: FormGroup;
  userRuleId: number;
  test:any;
  constructor(private userService: UserService,
    private route: ActivatedRoute,
    private location: Location,
    public fb: FormBuilder,
    private router: Router) {
    }

  ngOnInit(): void {
    this.route
    .queryParams
    .subscribe(params => {
      this.test = params;
      this.userRuleId = Number(params.ruleid);
      this.userName = params.username;
      this.initForm();
      this.reloadData();
    });

  }

  reloadData(): void {
    this.userService.getUserRules().subscribe(x => {
      this.userRules =  x;
    });
  }

  goBack(){
    this.location.back();
  }

  initForm(){
    this.form  = this.fb.group({
      userName : [this.userName,Validators.required],
      rule : [this.userRuleId,Validators.required]
    });
  }

  deleteMaterial(id: number): void {}

  Save(userRuleId){
    if(userRuleId == 0){
      alert('Please select rule')
    }else{
      this.userService.updateUserRules(this.form.value.userName, this.form.value.rule).subscribe(x => {
        this.router.navigate(['user-rule-mapper-list']);
      });
    }
  }

  materialDetails(id: number): void{
    this.router.navigate(['update-user', id]);
  }

}
