export class ItemCategoryModel {
  itemCategoryId: number;
  itemCategoryName: string;
  parentId: number;
    

    constructor(itemCategory?) {
        itemCategory = itemCategory || {};
        this.itemCategoryId = itemCategory.itemCategoryId || 0;
        this.itemCategoryName = itemCategory.itemCategoryName;
        this.parentId = itemCategory.parentId || 0;
      }
}
