import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FaqService } from '../faq/faq.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { RatingService } from 'app/main/administrative/rating/rating.service';


@Component({
    selector     : 'file-list',
    templateUrl  : './file-list.component.html',
    styleUrls    : ['./file-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class FileListComponent  implements OnInit {
    ratingList: string;
    isLoading: boolean = false;
  
    //'email',
    ratingListColumns: string[] = ['position', 'name',  'email', 'postcode', 'address'];
  
    constructor(
        private router: Router,
        private ratingService: RatingService,
    ) {}
  
    ngOnInit(): void {
        this.reloadData();
    }
  
    userId
    reloadData(): void {
      if (JSON.parse(localStorage.getItem('user')).id != null){
        this.userId = JSON.parse(localStorage.getItem('user')).id;
      }
      this.isLoading = true;
        this.ratingService.getContactToTradesperson(this.userId).subscribe(x => {
            this.ratingList = x;
            this.isLoading = false;
        });
    }
  
    checkDetail(id: number): void {
        this.router.navigate(['job-details', id]).then();
    }
  
  }
  
  