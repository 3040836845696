import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { RatingService } from 'app/main/administrative/rating/rating.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  tradeServiceReviews;
  averageRating             = 0;
  totalReview               = 0;
  tradeServiceReviewsId;

  feedbackText;

  jobName;
  userName;
  ratingDate;
  overallRating;
  estimateQuicklyProvided;
  experience;
  price;
  respectForProperty;
  jobQuality;
  userId;


  jobDetails;
  jobStartTime;
  estimatedBudget;
  contactInfo;
  email;
  phone;
  postcode;
  address;
  isLoading: boolean = false;
  


  constructor(
      private router: Router,
      private ratingService: RatingService,
      private route: ActivatedRoute,
      ) {
      this.route.params.subscribe(params => {
          this.tradeServiceReviewsId = (params.id);
      });
  }

  ngOnInit(): void {
      this.getRatingDetails(this.tradeServiceReviewsId);
  }

  acceptJob(){
      if (localStorage.getItem('user') !== null) {
          let userId = JSON.parse(localStorage.getItem('user')).userId;

          this.ratingService.approveRating(this.tradeServiceReviewsId, userId)
              .subscribe(
                  response => {
                      if(response === 200){
                          this.router.navigate(['/rating-management']);
                      }
                  },
                  error => {
                  console.log(error);
                  }
              );
          }
  }

  declineJOb(){
      if (localStorage.getItem('user') !== null) {
          let userId = JSON.parse(localStorage.getItem('user')).userId;

          this.ratingService.rejectRating(this.tradeServiceReviewsId, userId)
              .subscribe(
                  response => {
                      if(response === 200){
                          this.router.navigate(['/rating-management']);
                      }
                  },
                  error => {
                  console.log(error);
                  }
              );
          }
  }

  getRatingDetails(tradeServiceReviewsId): void{
    this.isLoading = true;
    this.ratingService.getContactToTradespersonDetail(tradeServiceReviewsId)
    .subscribe(
        response => {
            this.jobDetails = response.jobDescription;
            this.jobStartTime = response.jobStartDay;
            this.estimatedBudget = response.estimatedBudget;
            this.contactInfo = response.name;

            this.email = response.email;
            this.phone  = response.phoneNumber;
            this.postcode  = response.postcode;
            this.address  = response.address
            this.isLoading = false;
        },
        error => {
        console.log(error);
        }
    );
    }
}