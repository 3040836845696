import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

// import { AuthService } from 'app/auth/auth.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RatingService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  constructor(private http          : HttpClient,
              // private authService   : AuthService
              ) { }

  getRating(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetTradeServiceReviewsForVerification');
  }

  getRatingDetails(tradeServiceReviewsId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRatingDetails/' + tradeServiceReviewsId);
  }

  approveRating(tradeServiceReviewsId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/ApproveTradeServiceReview/'+ tradeServiceReviewsId + '/' + userId);
  }

  rejectRating(tradeServiceReviewsId, userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/RejectTradeServiceReview/'+ tradeServiceReviewsId + '/' + userId);
  }

  getRatingList(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRatingList/');
  }

  getRatingList2(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRatingList2/');
  }

  getApprovedRatingList(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetApprovedRatingList/');
  }

  getRejectedRatingList(): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRejectedRatingList/');
  }

  getContactToTradesperson(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetContactToTradesperson/' + userId);
  }

  getContactToTradespersonDetail(contactToTradespersonId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetContactToTradespersonDetail/' + contactToTradespersonId);
  }
  
  getRatingDetailsTest(tradeServiceReviewsId): Observable<any> {
    return this.http
    .get(environment.baseUrl + '/api/Tradesman/GetRatingDetailsTest/' + tradeServiceReviewsId);
  }
  
  // tslint:disable-next-line: typedef  GetTradeService
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
 
}
