import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AccountService } from './account.service';
// import { AuthService } from 'app/auth/auth.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/main/administrative/user/user.service';
import { first } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    returnUrl: string;
    passwordType = 'password';
    isSignIn: boolean;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _loginService: LoginService,
        private _userService: UserService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private toastr: ToastrService,
        //private authService: AuthService,
    )
    {
        this.isSignIn = false;
        if (this.accountService.userValue) {
            this.router.navigate(['/home']).then();
        }
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    get f(): any { return this.loginForm.controls; }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required]],
            password: ['', Validators.required]
        });
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        // this.getNavigation();
    }
    
    onSubmit(): void {
        this.isSignIn = true;
        this.accountService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate(['/home']).then();
                    this.isSignIn = false;
                },
                (error) => {
                    this.isSignIn = false;
                    this.toastr.error('Invalid email or password', 'TVS');
                    console.error(error);
                });
    }

    logIn(): void {
        localStorage.setItem('dataSource', this.loginForm.getRawValue().email);
    }
    
    changePasswordType(): void {
        if (this.passwordType === 'password') {
            this.passwordType = 'text';
        } else {
            this.passwordType = 'password';
        }
    }
}
