import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// import { AuthService } from 'app/auth/auth.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private messageSource   = new BehaviorSubject(0);
  currentMessage          = this.messageSource.asObservable();

  // idToken = JSON.parse(localStorage.getItem("user"));
  // req = new HttpHeaders();
  constructor(private http          : HttpClient,
              // private authService   : AuthService
              ) { }

  saveGeneralData(generalData): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/Administrative/SaveGeneralData', generalData);
  }

  changeMessage(message: number) {
    this.messageSource.next(message)
  }
  
  saveVetting(vetting): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/Administrative/SaveVettingStatus', vetting);
  }

  saveDescription(description): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/Administrative/SaveDescription', description);
  }
  
  savJobCategory(userRuleId, ruleTaskArray): Observable<any> {
    return this.http.post(environment.baseUrl +'/Administrative/SavJobCategory/'+ userRuleId, ruleTaskArray)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  saveLocation(val): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/Administrative/SaveLocation', val);
  }

  createTradeServiceAccount(userId): Observable<any> {
    return this
    .http.post(`${environment.baseUrl}` + '/api/Tradesman/CreateTradeServiceAccount', userId);
  }

  serviceWeCover(): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/ServiceWeCover');
  }

  ourServices(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GetOurServices/'+ tradeServiceId);
  }

  getPostalTowns(): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GetPostalTowns');
  }

  getTradeServices(userId): Observable<any> {
    return this
    .http.get(environment.baseUrl +'/Administrative/GetTradeService/' + userId);
  }

  goVetting(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GoVetting/' + userId);
  }

  getServicesCost(tradeServiceId, parentId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GetServicesCost/' + tradeServiceId + '/' + parentId);
  }
  
  getVettingFileByUserId(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GetVettingFileByUserId/' + userId);
  }

  getAccreditation(tradeServiceId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GetAccreditation/' + tradeServiceId);
  }

  getGalaryFiles(userId): Observable<any> {
    return this.http
    .get(environment.baseUrl +'/Administrative/GetGalaryFiles/' + userId);
  }

  getServices(userId): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/api/Tradesman/GetTradeService/' + userId);
  }

  getCompanyProfile(userId): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/api/Tradesman/GetCompanyProfile/' + userId);
  }

  getItemgategorys(): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/Itemcategory/GetSearchItemCategorys');
  }

  getTradeServiceById(tradeServiceId): Observable<any> {
    return this.http
    .get(`${environment.baseUrl}` + '/api/Tradesman/GetTradeServiceById/' + tradeServiceId);
  }

  saveTradeService(tradeService): Observable<any> {
    return this.http
    .post(environment.baseUrl +'/Administrative/SaveTradeService/', tradeService);
  }

  updateTradeService(tradeService): Observable<any> {
    const headers = { 'Content-Type': 'application/json', 'Accept': 'application/json'} 
      return this.http
      .post(`${environment.baseUrl}` +'/api/Tradesman/UpdateTradeService/', tradeService)    
  }
  
  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
 
}
