import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TradeService } from 'app/main/model/tradeService';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../../services.service';
// import {} 'assets/images/default-placeholder.png'

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  userId;
  galleryContainer = [];
  membershipContainer = [1];
  htmlContent = '';
  form: FormGroup;
  tradeService;

  jobGalaryFile1                          : File = null;
  jobGalaryImageUrl1                     : string;

  jobGalaryFile2                          : File = null;
  jobGalaryImageUrl2                      : string;

  jobGalaryFile3                          : File = null;
  jobGalaryImageUrl3                      : string;

  jobGalaryFile4                          : File = null;
  jobGalaryImageUrl4                      : string;

  jobGalaryFile5                          : File = null;
  jobGalaryImageUrl5                      : string;

  jobGalaryFile6                          : File = null;
  jobGalaryImageUrl6                      : string;

  jobGalaryFile7                          : File = null;
  jobGalaryImageUrl7                      : string;

  jobGalaryFile8                          : File = null;
  jobGalaryImageUrl8                      : string;

  jobGalaryFile9                          : File = null;
  jobGalaryImageUrl9                      : string;

  jobGalaryFile10                          : File = null;
  jobGalaryImageUrl10                      : string;



  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
          {class: 'arial', name: 'Arial'},
          {class: 'times-new-roman', name: 'Times New Roman'},
          {class: 'calibri', name: 'Calibri'},
          {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
          {
              name: 'quote',
              class: 'quote',
          },
          {
              name: 'redText',
              class: 'redText'
          },
          {
              name: 'titleText',
              class: 'titleText',
              tag: 'h1',
          },
      ],
      uploadUrl: 'v1/image',
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
          ['bold', 'italic'],
          ['fontSize']
      ]
  };              
  disabled = false;
  constructor(private formBuilder       : FormBuilder, 
              private servicesService   : ServicesService,
              private toastr            : ToastrService) { 
    this.tradeService = new TradeService();
  }
  
  ngOnInit() {
    this.jobGalaryImageUrl1 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl2 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl3 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl4 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl5 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl6 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl7 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl8 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl9 = 'assets/images/default-placeholder.png';
    // this.jobGalaryImageUrl10 = 'assets/images/default-placeholder.png';

      // this.jobGalaryImageUrl1 = x.resourceFile
    this.getTradeService();
    //this.getJobGalleryFiles();

    this.form = new FormGroup({
      serviceDescription			          : new FormControl(null),
      videoLink		                    	: new FormControl(null),
      
      jobGallery1		                    : new FormControl(null),
      jobGallery2		                    : new FormControl(null),
      jobGallery3		                    : new FormControl(null),
      jobGallery4		                    : new FormControl(null),
      jobGallery5		                    : new FormControl(null),
      jobGallery6		                    : new FormControl(null),
      jobGallery7		                    : new FormControl(null),
      jobGallery8		                    : new FormControl(null),
      jobGallery9		                    : new FormControl(null),
      jobGallery10		                  : new FormControl(null),
      
    });
  }

  addNewAccreditation1(imageUrl){
    this.jobGalaryImageUrl1 ='assets/images/default-placeholder.png';
  }

  status: boolean = true;
  addNewAccreditation2(imageUrl){
    this.status = !this.status;   
    this.jobGalaryImageUrl2 ='assets/images/default-placeholder.png';
  }

  addNewAccreditation3(imageUrl){
    this.jobGalaryImageUrl3 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation4(imageUrl){
    this.jobGalaryImageUrl4 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation5(imageUrl){
    this.jobGalaryImageUrl5 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation6(imageUrl){
    this.jobGalaryImageUrl6 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation7(imageUrl){
    this.jobGalaryImageUrl7 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation8(imageUrl){
    this.jobGalaryImageUrl8 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation9(imageUrl){
    this.jobGalaryImageUrl9 ='assets/images/default-placeholder.png';
  }
  addNewAccreditation10(imageUrl){
    this.jobGalaryImageUrl10 ='assets/images/default-placeholder.png';
  }



  getTradeService(){
    if (localStorage.getItem('user') !== null) {
        this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
      
    this.servicesService.getTradeServices(this.userId)
        .subscribe((response) => {
          if(response.isChecked === true){
            this.form.disable();
            this.disabled = true;
          }
          this.getJobGalleryFiles(response.tradeServiceId);
          this.form.patchValue(response);

          
    },
  );
  }
  galleryDocs;

  getJobGalleryFiles(tradeServiceId){

    this.servicesService.getGalaryFiles(tradeServiceId)
        .subscribe((response) => {
          this.galleryDocs = response;
          this.galleryDocs.forEach(x=> {
            if(x.fileName === 'jobGallery1')
            {
             this.jobGalaryImageUrl1 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery2')
            {
              this.jobGalaryImageUrl2 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery3')
            {
              this.jobGalaryImageUrl3 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery4')
            {
              this.jobGalaryImageUrl4 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery5')
            {
              this.jobGalaryImageUrl5 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery6')
            {
              this.jobGalaryImageUrl6 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery7')
            {
              this.jobGalaryImageUrl7 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery8')
            {
              this.jobGalaryImageUrl8 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery9')
            {
              this.jobGalaryImageUrl9 = x.resourceFile
            }
            else if(x.fileName === 'jobGallery10')
            {
              this.jobGalaryImageUrl10 = x.resourceFile
            }
          }); 
      },
    );
  }
  
  saveDescription(tabName: string) {
    if(this.form.value.serviceDescription === null || this.form.value.serviceDescription === ''){
      this.toastr.success('Please provide your service description.', 'BRP');
      return
    }
    if(this.form.value.serviceDescription.split(' ').length < 30){
      this.toastr.success('Description must be greater than 30 word.', 'BRP');
      return
    }

    if (localStorage.getItem('user') !== null) {
      this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
    const formData = new FormData();
    formData.append('deleted', JSON.stringify(this.galleryContainer));
    formData.append('userId', this.userId);
    formData.append('serviceDescription', this.form.value.serviceDescription);
    formData.append('videoLink', this.form.value.videoLink);

    formData.append('jobGallery1', this.jobGalaryFile1);
    formData.append('jobGallery2', this.jobGalaryFile2);
    formData.append('jobGallery3', this.jobGalaryFile3);
    formData.append('jobGallery4', this.jobGalaryFile4);
    formData.append('jobGallery5', this.jobGalaryFile5);
    formData.append('jobGallery6', this.jobGalaryFile6);
    formData.append('jobGallery7', this.jobGalaryFile7);
    formData.append('jobGallery8', this.jobGalaryFile8);
    formData.append('jobGallery9', this.jobGalaryFile9);
    formData.append('jobGallery10', this.jobGalaryFile10);
    
    this.servicesService.saveDescription(formData)
    .subscribe((response) => {
      if(response === 200){
        for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
          if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
          {
              (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
          }
        }
      }
    },
    (error) => {
      alert(error);
    }
    );
    
    // for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    // if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
    //    {
    //       (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
    //    }
    //  }
  }

  onJobGalleryFile1(fileInput: any) {
    this.jobGalaryFile1 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl1 = reader.result as string;
      };
    }
  }

  onJobGalleryFile2(fileInput: any) {
    this.jobGalaryFile2 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl2 = reader.result as string;
      };
    }
  }

  onJobGalleryFile3(fileInput: any) {
    this.jobGalaryFile3 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl3 = reader.result as string;
      };
    }
  }

  onJobGalleryFile4(fileInput: any) {
    this.jobGalaryFile4 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl4 = reader.result as string;
      };
    }
  }

  onJobGalleryFile5(fileInput: any) {
    this.jobGalaryFile5 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl5 = reader.result as string;
      };
    }
  }

  onJobGalleryFile6(fileInput: any) {
    this.jobGalaryFile6 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl6 = reader.result as string;
      };
    }
  }

  onJobGalleryFile7(fileInput: any) {
    this.jobGalaryFile7 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl7 = reader.result as string;
      };
    }
  }
  onJobGalleryFile8(fileInput: any) {
    this.jobGalaryFile8 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl8 = reader.result as string;
      };
    }
  }

  onJobGalleryFile9(fileInput: any) {
    this.jobGalaryFile9 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl9 = reader.result as string;
      };
    }
  }

  onJobGalleryFile10(fileInput: any) {
    this.jobGalaryFile10 = <File>fileInput.target.files[0];

    const reader = new FileReader();
    if(fileInput.target.files && fileInput.target.files.length) {
      const [file] = fileInput.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.jobGalaryImageUrl10 = reader.result as string;
      };
    }
  }
  
  previousTab(tabName: string) {

   

    // for (let i =0; i< document.querySelectorAll('.mat-tab-label-content').length; i++) {
    // if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) 
    //    {
    //       (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
    //    }
    //  }
  }
  
  processFile(imageInput: any): void {
    const file: File = imageInput.files[0];
  }

  addNewImageBox(): void {
      this.galleryContainer.push(this.galleryContainer.length);
  }

  test: File;
  deleteImageBox(image): void {
    
    switch (image) {
      case 'jobGalaryImageUrl1':
        this.jobGalaryImageUrl1 ='assets/images/default-placeholder.png';
        this.galleryContainer.push('jobGallery1');
          break;

      case 'jobGalaryImageUrl2':
        this.jobGalaryImageUrl2 ='assets/images/default-placeholder.png';
        this.galleryContainer.push('jobGallery2');
          break;

      case 'jobGalaryImageUrl3':
       this.jobGalaryImageUrl3 ='assets/images/default-placeholder.png';
       this.galleryContainer.push('jobGallery3');
          break;

      case 'jobGalaryImageUrl4':
        this.jobGalaryImageUrl4 ='assets/images/default-placeholder.png';
        this.galleryContainer.push('jobGallery4');
          break;

      case'jobGalaryImageUrl5':
      this.jobGalaryImageUrl5 ='assets/images/default-placeholder.png';
      this.galleryContainer.push('jobGallery5');
          break;

      case 'jobGalaryImageUrl6':
        this.jobGalaryImageUrl6 ='assets/images/default-placeholder.png';
        this.galleryContainer.push('jobGallery6');
          break;

      case'jobGalaryImageUrl7':
      this.jobGalaryImageUrl7 ='assets/images/default-placeholder.png';
      this.galleryContainer.push('jobGallery7');
          break;

      case'jobGalaryImageUrl8':
      this.jobGalaryImageUrl8 ='assets/images/default-placeholder.png';
      this.galleryContainer.push('jobGallery8');
          break;

      case'jobGalaryImageUrl9':
      this.jobGalaryImageUrl9 ='assets/images/default-placeholder.png';
      this.galleryContainer.push('jobGallery9');
          break;

      case'jobGalaryImageUrl10':
      this.jobGalaryImageUrl10 ='assets/images/default-placeholder.png';
      this.galleryContainer.push('jobGallery10');
          break;

      default:
          console.log("No such day exists!");
          break;
  }







    // if(this.jobGalaryImageUrl1){
    //   this.jobGalaryImageUrl1 ='assets/images/default-placeholder.png';
    //   // this.jobGalaryImageUrl1 = 'assets/images/default-placeholder.png';
    //   // this.jobGalaryImageUrl1 = x.resourceFile
    // }
    // else if(image === this.jobGalaryImageUrl2){
    //   this.jobGalaryImageUrl2 = null;
    // }
    // else if(image === this.jobGalaryImageUrl3){
    //   this.jobGalaryImageUrl3 = null;
    // }
    // else if(image === this.jobGalaryImageUrl4){
    //   this.jobGalaryImageUrl4 = null;
    // }
    // else if(image === this.jobGalaryImageUrl5){
    //   this.jobGalaryImageUrl5 = null;
    // }
    // else if(image === this.jobGalaryImageUrl6){
    //   this.jobGalaryImageUrl6 = null;
    // }
    // else if(image === this.jobGalaryImageUrl7){
    //   this.jobGalaryImageUrl7 = null;
    // }
    // else if(image === this.jobGalaryImageUrl8){
    //   this.jobGalaryImageUrl8 = null;
    // }
    // else if(image === this.jobGalaryImageUrl9){
    //   this.jobGalaryImageUrl9 = null;
    // }
    // else if(image === this.jobGalaryImageUrl10){
    //   this.jobGalaryImageUrl10 = null;
    // }

    
      // this.galleryContainer.splice(index, 1);
  }
  
  addAnotherMembership(): void {
      this.membershipContainer.push(this.membershipContainer.length);
  }
}
