import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserModel } from 'app/main/model/userModel';
import { UserService } from '../user.service';
import { MatSort, Sort } from '@angular/material';
export interface UserListModel{
  'position': number, 
  'userName': string,  
  'email': string, 
  'status': string
}
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {
  
  // materialList: any;
  dataSource: MatTableDataSource<UserListModel>;
  userList: UserModel[] = [];
  isLoading: boolean = false;
  userListColumns: string[] = ['position', 'userName',  'email', 'status',  'edit'];
  @ViewChild(MatSort,{static: false}) sort: MatSort;

  constructor(private userService: UserService,
              private router: Router) {
    this.dataSource = new MatTableDataSource<UserListModel>();
  }

  ngOnInit(): void {
    this.reloadData();
  }
  
  ngAfterViewInit(): void{
    this.dataSource.sort = this.sort;
  }

  reloadData(): void {
    this.isLoading = true;
    this.userService.getUsers().subscribe(x => {
      this.userList =  x;
      this.userList.filter(x => {
         x.status = x.isActive?'Active':'InActive';
      })
      this.isLoading = false;
    });
  }

  deleteMaterial(id: number): void {}

  materialDetails(id: number): void{
    this.router.navigate(['update-user', id]);
  }

  addNewUser(){
    this.router.navigate(["/add-new-user"]);
  }

  sortByStatus($event: Sort){
    console.log(this.sort);
    console.log($event);
  }

}
