import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { MaterialModel } from 'app/main/model/materialModel';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-task-create',
  templateUrl: './user-task-create.component.html',
  styleUrls: ['./user-task-create.component.scss']
})
export class UserTaskCreateComponent implements OnInit {

  id: number;
  form: FormGroup;
  materialModel: MaterialModel;
  buttonText: string;


  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    private userService: UserService,) {
      this.materialModel = new MaterialModel();
     }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.form                      = this.createDrawHomeForm();

    this.id = this.route.snapshot.params['id'];
    if (this.id === undefined){
      this.buttonText        = 'Save';
      this.materialModel = new MaterialModel();
    }else{
      this.buttonText        = 'Update';   
      this.userService.getTaskById(this.id)
      .subscribe(data => {
        this.form.setValue(data[0]);
      }, error => console.log(error));
    }
  }

  createDrawHomeForm(): FormGroup{
    return this.fb.group({
      materialId              : [this.materialModel.materialId],
      materialName            : [this.materialModel.materialName],
    });
  }

  submit(): void{}

}
