export class UserModel {
  userId: number;
  positioin: number;
  userName: string;
  email: number;
  password: string;
  tradesman: boolean;
  createdDate: string;
  isActive: boolean;
  status: string;
}
  